import { useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import ShowCurrencies from '../../../utils/showCurrencies';
import CustomButton from '../../../components/general/button';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const AppealGivingProductBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const ref = useRef(null);
    const { product, currency, onAddQty, onReduceQty, onAddToGiving } = props;
    const [showMore] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(product);
    const [isAnyAmount, setIsAnyAmount] = useState(false);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleCustomProductAmount = (product) => {
        if (Number(product['aud']) < 2) {
            setIsAnyAmount(true);
        } else {
            setIsAnyAmount(false);
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        product && handleCustomProductAmount(product)
        product && setCurrentProduct(product)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full rounded-xl shadow-lg bg-white overflow-hidden mx-auto p-2 md:p-3 md:min-w-[350px]- border border-stone-300">
            <div className="w-full flex justify-between items-center">
                <h2 className='text-lg md:text-2xl text-LightBlue font-bold'>
                    {isAnyAmount ? 'Any Amount' : <>
                        {ShowCurrencies(currency, product[currency.toLowerCase()], false)} <small className={`uppercase`}>{currency}</small>
                    </>}
                </h2>
                <span className='text-[#AAAAAA] text-sm'>
                    {currentProduct ? `${currentProduct.total_count} Claimed` : <Skeleton height={48} />}
                </span>
            </div>
            <h3 className="w-full text-DarkerBlue lg:text-lg font-medium h-[34px] sm:h-[32px] lg:h-[48px] my-2 flex items-center">
                {currentProduct ? currentProduct.name : <Skeleton height={48} />}
            </h3>
            <div className="w-full relative">
                {currentProduct ? <>
                    <div className={`relative overflow-hidden ${showMore ? 'h-auto' : 'max-h-[60px] md:max-h-[60px]- h-[60px]'} ${(currentProduct.short_description || currentProduct.description).split(' ').length > 28 ? 'mb-1' : 'mb-2'}`}>
                        <p ref={ref} className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>
                            {currentProduct.short_description || currentProduct.description}
                        </p>
                    </div>
                </> : <Skeleton count={4} />}
            </div>
            <div className="w-full flex items-center mt-1 md:mt-2">
                {currentProduct
                    ? currentProduct.isAdded
                        ? <div className="flex h-10 items-center justify-between w-full">
                            <span className="text-green-700 italic text-xs">Item Added...</span>
                            <div className="flex items-center">
                                <span className="mr-5 text-[22px] ">
                                    {currentProduct.quantity}
                                </span>
                                <div className="flex flex-col w-5 mr-3">
                                    <div className="w-8 flex cursor-pointer text-[#00a3da]" onClick={() => onAddQty()}>
                                        <ChevronUpIcon />
                                    </div>
                                    <div className="w-8 flex cursor-pointer text-[#00a3da]" onClick={() => onReduceQty()}>
                                        <ChevronDownIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <CustomButton
                            onClick={() => onAddToGiving()}
                            type={'button'}
                            title={'Add to Giving'}
                            className={`rounded-full !h-10`} />
                    : <Skeleton height={40} />
                }
            </div>
        </div>
    );
}

export default AppealGivingProductBox;