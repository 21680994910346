import axios from "axios";

const Base = process.env.REACT_APP_V2_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;
let config = {
  headers: {
    "X-user-matw": Header
  },
};

function login(data){
    return axios.post(`${Base}/auth/login`, data, config)
}

function confirmRegister(data){
    return axios.post(`${Base}/auth/verify/register`, data, config)
}

function confirmLogin(data){
  return axios.post(`${Base}/auth/verify/login`, data, config)
}

function register(data){
  return axios.post(`${Base}/auth/register`, data, config);
}

function forgotPassword(data){
  return axios.post(`${Base}/auth/forgot-password`, data, config);
}

function verifyForgotPassword(data){
  return axios.post(`${Base}/auth/verify/forgotPassword`, data, config);

}

function getCountryCodes(){
  return axios.get(`${Base}/countries/`, config);
}

export {
    login,
    confirmRegister,
    register,
    forgotPassword,
    verifyForgotPassword,
    confirmLogin,
    getCountryCodes
}