import { useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { BASKET_CACHE_KEY, HandleLocalStorage, IsExpired } from './handleLocalStorage';
import HandleShowAdminCost from './handleAdminCost';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../store/user';
import { useReducer } from 'react';
import { store } from '../store/store';
import { pushEventToQueue } from '../store/gtm';

const useHandleAddProduct = () => {
  const navigate = useNavigate();
  // const pixelID = localStorage.getItem('TikTokID')
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const initialState = {
    timestamp: Date.now() + 24 * 60 * 60 * 7 * 1000,
    stripe_customer_id: '',
    currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
    mode: 'payment',
    adminCost: 0,
    grandTotal: 0,
    recurring: 'one-off',
    paymentFlow: '',
    donor: {
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      postal_code: '',
      countryCode: '',
      country: '',
    },
    user: {
      currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
      email: '',
      id: '',
      donor_id: '',
      name: '',
      phone: '',
      plaque_name: '',
      address: '',
      postal_code: '',
      city: '',
    },
    products: [
      {
        product: null,
        quantity: 1,
        amount: 0,
        total: 0,
        currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
        recurring: 'one-off',
        landing_page: '',
        onBehalf: 'For the sake of Allah SWT',
      },
    ],
  };
  const [basketStates, setBasketStates] = useReducer((basketStates, setBasketStates) => ({ ...basketStates, ...setBasketStates }), initialState);
  // useEffect(() => {
  //   if (basketStatesFromLocalStorage) {
  //     setBasketStates(basketStatesFromLocalStorage);
  //   }
  // }, [basketStatesFromLocalStorage]);
  const addProduct = (product, currency, type = 'single', openOnNewTab = false) => {
    let tempBasket = basketStates;
    if (basketStatesFromLocalStorage && !IsExpired(basketStatesFromLocalStorage)) {
      tempBasket = basketStatesFromLocalStorage;
      setBasketStates(basketStatesFromLocalStorage);
    }
    let temp_landing_page = window.location.pathname === '/' ? '/home' : window.location.pathname;
    if (type === 'single') {
      let product_quantity =
        product['aud'] < 2 ? (product.quantity ? (product.quantity === 1 ? 1 : product.quantity) : 100) : product.quantity ? product.quantity : 1;
      let product_recurring = '';
      if (product.recurring === 'Allowed') {
        tempBasket.recurring ? (product_recurring = tempBasket.recurring) : (product_recurring = 'one-off');
      } else {
        tempBasket.recurring !== product.recurring ? (product_recurring = product.recurring) : (product_recurring = product.recurring);
      }
      let product_mode = product_recurring === 'one-off' ? 'payment' : 'subscription';
      let tempProduct = {
        product: {
          AUD: product.aud,
          CAD: product.cad,
          EUR: product.eur,
          GBP: product.gbp,
          MYR: product.myr,
          SGD: product.sgd,
          USD: product.usd,
          AED: product.aed,
          IDR: product.idr,
          category: product.category,
          category_id: product.category_id,
          creator: product.creator,
          currency: currency,
          custom_amount: product.custom_amount,
          description: product.description,
          image_link: product.image_link,
          name: product.name,
          paypal_plans: product.paypal_plans,
          paypal_subscription_plans: product.paypal_subscription_plans,
          // recurring: product.recurring,
          recurring: product_recurring,
          status: product.status,
          unit_amount: product[currency.toLowerCase()],
        },
        quantity: product_quantity,
        amount: product[currency.toLowerCase()],
        total: Number(product_quantity) * Number(product[currency.toLowerCase()]),
        currency: currency,
        recurring: product_recurring,
        landing_page: temp_landing_page,
        onBehalf: 'For the sake of Allah SWT',
      };
      // For laylatul qadr  only
      if (product.start_date) {
        tempProduct = Object.assign({}, tempProduct, {
          start_date: product.start_date,
          end_date: product.end_date,
          continue_donation: product.continue_donation,
          grandTotal: tempProduct.total,
          currency: 'USD',
        });
        tempBasket.products = [tempProduct.product]; //reset to one product only
      }
      if (tempBasket.grandTotal === 0 || !tempBasket.products[0].product) {
        tempBasket.grandTotal = Number(tempProduct.total);
        tempBasket.products = [tempProduct];
        tempBasket.currency = currency;
        tempBasket.recurring = product_recurring;
        tempBasket.mode = product_mode;
        HandleLocalStorage(tempBasket);
        handleAddToCartDataLayer(tempProduct, tempBasket.currency);
        let jsonObj = {
          product: product && product.creator,
          currency: currency,
          id: product && product.id,
          quantity: product_quantity,
          category_id: product && product.category_id,
          landing_page: temp_landing_page,
          recurring: product_recurring,
        };
        if (product.start_date) {
          jsonObj.isLaylatuQadr = true;
        }
        openOnNewTab
          ? window.open(
              `/checkout?id=${Base64.encode(
                JSON.stringify({
                  ...jsonObj,
                }),
              )}`,
              '_blank',
            )
          : navigate(
              `/checkout?id=${Base64.encode(
                JSON.stringify({
                  ...jsonObj,
                }),
              )}`,
            );
        // navigate(
        //   `/checkout?id=${Base64.encode(
        //     JSON.stringify({
        //       product: product && product.creator,
        //       currency: currency,
        //       id: product && product.id,
        //       quantity: product_quantity,
        //       category_id: product && product.category_id,
        //       recurring: product_recurring,
        //     }),
        //   )}`,
        // );
      } else {
        tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempProduct.total);
        tempBasket.products.push(tempProduct);
        tempBasket.mode = product_mode;
        if (tempBasket.currency !== currency) {
          let newTempGrandTotal = 0;
          tempBasket.products.map(item => {
            if (item.product) {
              item.currency = currency;
              item.product.currency = currency;
              item.total = item.quantity * item.product[currency];
              item.amount = item.product[currency];
              newTempGrandTotal += item.quantity * item.product[currency];
            }
            return item;
          });
          newTempGrandTotal = tempBasket.adminCost ? Number(newTempGrandTotal) + Number(HandleShowAdminCost(currency)) : Number(newTempGrandTotal);
          tempBasket.adminCost = tempBasket.adminCost ? Number(HandleShowAdminCost(currency)) : 0;
          tempBasket.grandTotal = newTempGrandTotal;
        }
        tempBasket.products.map(item => {
          item.recurring = product_recurring;
          return item;
        });
        tempBasket.recurring = product_recurring;
        tempBasket.currency = currency;
        HandleLocalStorage(tempBasket);
        handleAddToCartDataLayer(tempProduct, tempBasket.currency);
        openOnNewTab
          ? window.open(
              `/checkout?id=${Base64.encode(
                JSON.stringify({
                  product: product && product.creator,
                  currency: currency,
                  id: product && product.id,
                  quantity: product_quantity,
                  category_id: product && product.category_id,
                  recurring: product_recurring,
                  landing_page: temp_landing_page,
                }),
              )}`,
              '_blank',
            )
          : navigate(
              `/checkout?id=${Base64.encode(
                JSON.stringify({
                  product: product && product.creator,
                  currency: currency,
                  id: product && product.id,
                  quantity: product_quantity,
                  category_id: product && product.category_id,
                  recurring: product_recurring,
                  landing_page: temp_landing_page,
                }),
              )}`,
            );
      }
    } else {
      let product_recurring = tempBasket.recurring ? tempBasket.recurring : 'one-off';
      let product_mode = product_recurring === 'one-off' ? 'payment' : 'subscription';
      let tempProducts = [];
      // let tljempGrandTotal = 0;
      product.map(prd => {
        let product_quantity = prd.quantity ? prd.quantity : 1;
        let tempPrd = {
          product: {
            AUD: prd.aud,
            CAD: prd.cad,
            EUR: prd.eur,
            GBP: prd.gbp,
            MYR: prd.myr,
            SGD: prd.sgd,
            USD: prd.usd,
            AED: prd.aed,
            IDR: prd.idr,
            category: prd.category,
            category_id: prd.category_id,
            creator: prd.creator,
            currency: currency,
            custom_amount: prd.custom_amount,
            description: prd.description,
            image_link: prd.image_link,
            name: prd.name,
            paypal_plans: prd.paypal_plans,
            paypal_subscription_plans: prd.paypal_subscription_plans,
            recurring: product_recurring,
            status: prd.status,
            unit_amount: prd[currency.toLowerCase()],
          },
          quantity: product_quantity,
          amount: prd[currency.toLowerCase()],
          total: Number(product_quantity) * Number(prd[currency.toLowerCase()]),
          currency: currency,
          recurring: product_recurring,
          landing_page: temp_landing_page,
          onBehalf: 'For the sake of Allah SWT',
        };
        tempProducts.push(tempPrd);
        // tempGrandTotal += Number(product_quantity) * Number(prd[currency.toLowerCase()]);
        handleAddToCartDataLayer(tempPrd, currency);
        return prd;
      });
      // tempBasket.grandTotal = Number(tempGrandTotal);
      // tempBasket.products = tempProducts;
      // let newProducts = tempBasket.products.concat(tempProducts);
      let newProducts = [];
      if (tempBasket.products[0].product) {
        newProducts = tempBasket.products.concat(tempProducts);
      } else {
        newProducts = tempProducts;
      }
      tempBasket.products = newProducts;
      let tempTotalProducts = 0;
      newProducts.map(item => {
        item.product ? (tempTotalProducts += item.total) : (tempTotalProducts = 0);
        return null;
      });
      tempBasket.grandTotal = Number(tempTotalProducts) + Number(tempBasket.adminCost);
      tempBasket.currency = currency;
      tempBasket.recurring = product_recurring;
      tempBasket.mode = product_mode;
      console.log('before navigate=', tempBasket);
      HandleLocalStorage(tempBasket);
      navigate(`/checkout`);
    }
  };
  return [addProduct];
};

const handleAddToCartDataLayer = (product, currency) => {
  let dataLayerTemp = {
    event: 'add_to_cart',
    ecommerce: {
      value: product.total,
      currency: currency.toUpperCase(),
      items: [
        {
          item_id: product.product.creator,
          item_name: product.product.name,
          item_brand: product.product.category || '',
          item_category: product.product.category || '',
          item_description: product.product.description || '',
          price: product.amount,
          quantity: product.quantity,
        },
      ],
    },
  };
  store.dispatch(pushEventToQueue(dataLayerTemp));

  let obj = {
    content_type: 'product',
    content_id: product.product.creator,
    currency: currency.toUpperCase(),
    // value: tempAmount * tempQuantity,
    value: product.total,
    price: product.amount,
    quantity: product.quantity,
    content_category: product.category ? product.category : 'donation',
    content_name: product.product.name,
    description: product.product.description,
  };
  window.ttq &&
    window.ttq.track('AddToCart', {
      ...obj,
    });
};

export default useHandleAddProduct;
