import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import services from '../../services';
import Layout from '../../components/general/layout';
import ImageCard from './components/image-card';
import DefaultTextSection from './components/default-text-section';
import Highlights from './components/highlights';
import VideoBanner from './components/video-banner';
import ImageBanner from './components/image-banner';
import ImageContentCardWithUrl from './components/image-content-cards-with-url';
import ProductsGroupCard from './components/productsGroupCard';
import ProductCardGroup from './components/productCardGroup';
import FreeHtml from './components/freeHtml';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import ImageCarouselStyle from './components/imageCarouselStyle';
import DynamicVideoCards from './components/dynamicVideoCards';
import DonationFormWidget from './components/donation-form';
import TabbedProductsCarousel from './components/tabbed-products-carousel';
import Faqs from './components/faqs';

const getWidgetLayout = widget => {
  const { alias } = widget;
  switch (alias) {
    case 'story_group':
      return Highlights;
    case 'image_card':
      return ImageCard;
    case 'donation_form':
      return DonationFormWidget;
    case 'default_text_section':
      return DefaultTextSection;
    case 'video_banner':
      return VideoBanner;
    case 'banner_image':
      return ImageBanner;
    case 'image_and_content_cards_with_url':
      return ImageContentCardWithUrl;
    case 'products_group_card':
      return ProductsGroupCard;
    case 'product_card_groups':
      return ProductCardGroup;
    case 'free_html':
      return FreeHtml;
    case 'image_carousel_style':
      return ImageCarouselStyle;
    case 'dynamic_video_cards':
      return DynamicVideoCards;
    case 'tabbed_products_carousel':
      return TabbedProductsCarousel;
    case 'faqs':
      return Faqs;
    default:
      console.log('No widget found for alias:', alias);
      return null;
  }
};

const DynamicPage = () => {
  const { alias, subAlias } = useParams();
  const [layoutData, setLayoutData] = useState({ widgets: [] });
  const navigate = useNavigate();

  const isHomePage = !alias;

  const getPageLayout = async () => {
    try {
      const queryAlias = isHomePage ? 'webhomepage' : subAlias ? `${alias}/${subAlias}` : alias;
      const {
        data: { data },
      } = await services.getContent(`/layouts/${queryAlias}`);
      if (data.redirect?.code === 301) {
        window.location = data.redirect.url;
      } else {
        setLayoutData(data);
      }
    } catch (error) {
      navigate('/');
    }
  };

  useEffect(() => {
    getPageLayout();
  }, [alias]);

  const { widgets, ogImage, template } = layoutData;
  const hideNavigationBars = template === 'blank';

  return (
    <>
      <Helmet>
        {parse(`${widgets?.find(x => x.alias === 'seo')?.seo[0]?.html?.value}`)}
        {ogImage && <meta property="og:image" content={ogImage} />}
      </Helmet>
      <Layout hideHighlights hideNavigationBars={hideNavigationBars}>
        {widgets.map((widget, index) => {
          const Layout = getWidgetLayout(widget);
          if (!Layout) return null;
          const data = widget[widget.alias];
          return <Layout key={index} data={data} layout={layoutData} />;
        })}
      </Layout>
    </>
  );
};

export default DynamicPage;
