import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProducts } from '../../../../services/content';
import { selectSelectedCurrency } from '../../../../store/user';
import TabContent from './tab-content';
import parse from 'html-react-parser';

const ProductCardGroup = props => {
  const { data } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */
  const [activeTab, setActiveTab] = useState(0);
  const [currentProducts, setCurrentProducts] = useState(null);
  const [currentContent, setCurrentContent] = useState('');
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  const handleActiveTabChange = async (val, index, content) => {
    setActiveTab(index);
    try {
      const res = await getProducts(val.request?.url);
      if (res.status === 200) {
        setCurrentProducts(res.data?.data);
      }
      setCurrentContent(content);
    } catch (err) {
      console.error(err);
    }
  };

  const hasTarget = !!data[activeTab]?.fields.with_target?.value;
  const withGroup = !!data[activeTab]?.fields?.product?.custom_attributes?.withGroup;

  useEffect(() => {
    handleActiveTabChange(data[0]?.fields?.product, 0, data[0]?.fields?.content?.value);
  }, []);

  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {data.map(({ fields }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => handleActiveTabChange(fields.product, index, fields.content?.value)}
              className={`${
                index === activeTab ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } text-[15px] md:text-base- rounded-full max-[500px]:col-span-2- p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-['Montserrat'] font-bold`}
            >
              {fields.name?.value}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {currentContent && <p className="text-white text-[18px] text-center mb-4">{parse(currentContent)}</p>}
            <div ref={contentRef}>
              {currentProducts && <TabContent withGroup={withGroup} products={currentProducts} currency={selectedCurrency} hasTarget={hasTarget} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductCardGroup;
