import { Link } from 'react-router-dom';
import zakatImg from '../../images/highlights/zakat.png';
import waterImg from '../../images/highlights/water.png';
import orphanImg from '../../images/highlights/orphan.png';
import aliImg from '../../images/highlights/alibanat.png';
import palestineImg from '../../images/highlights/palestine.png';
import callImg from '../../images/highlights/call.png';
import sadaqahImg from "../../images/highlights/sadaqah.png"
// import subscribeImg from '../../images/highlights/subscribe.png';
import subscribeImg from '../../images/highlights/regular-giving.png';
import legacyImg from '../../images/highlights/legacy.png';
import muharramImg from "../../images/highlights/muharram.png"
// import qurbanImg from '../../images/highlights/qurban.png';
// import subscribeJson from '../../images/highlights/subscribe.json';
// import phoneJson from '../../images/highlights/phone.json';
// import Lottie from 'react-lottie';
// import { useState } from 'react';
// import zakatCalcImg from '../../images/highlights/icon-calc.png';
const Highlights = () => {
    const links = [
        { title: 'Palestine', image: palestineImg, link: '/crisis-and-emergencies/palestine', alt: 'Donate to Palestine', imgTitle: 'Donate to Palestine' },
        // { title: 'Qurban', image: qurbanImg, link: '/qurban', alt: 'Qurban', imgTitle: 'Qurban' },
        // { title: 'Ten Nights', image: laylaTulQadrImg, link: '/ramadan/last-10-nights',alt:'matw',imgTitle:''  },
        // { title: 'Zakat Al Fitr', image: zakatAlFitrImg, link: '/zakat/zakat-al-fitr',alt:'matw',imgTitle:''  },
        // { title: 'Ramadan', image: ramadanImg, link: '/ramadan',alt:'matw',imgTitle:''  },
        // { title: 'Orphans', image: orphanImg, link: '/orphans/support-an-orphan',alt:'matw',imgTitle:''  },
        { title: 'Orphan Support', image: orphanImg, link: '/orphans',alt:'matw',imgTitle:''  },
        { title: 'Sadaqah Jariyah', image: sadaqahImg, link: '/sadaqah-jariyah', alt: 'zakat calculator', imgTitle: '' },
        { title: 'Muharram', image: muharramImg, link: '/muharram', alt: 'Muharram', imgTitle: '' },
        { title: 'Zakat', image: zakatImg, link: '/zakat' ,alt:'matw',imgTitle:'' },
        { title: 'Ali Banat', image: aliImg, link: '/ali-banat' ,alt:'Ali Banat',imgTitle:'' },
        { title: 'Regular Giving', image: subscribeImg, link: '/subscription', alt: 'matw', imgTitle: '' },
        { title: 'Water Aid', image: waterImg, link: '/food-and-water-aid/water-aid',alt:'matw',imgTitle:''  },
        // { title: 'Libya', image: libyaImg, link: '/crisis-and-emergencies/libya-floods',alt:'matw',imgTitle:''  },
        // { title: 'Morocco', image: moroccoImg, link: '/crisis-and-emergencies/morocco',alt:'matw',imgTitle:''  },
        { title: 'Legacy', image: legacyImg, link: '/loved-ones-legacy',alt:'matw',imgTitle:''  },
        // { title: 'Sacrifice', image: sacrificeImg, link: '/sadaqah-jariyah/sacrifice',alt:'matw',imgTitle:''  },
        { title: 'Contact', image: callImg, link: '/contact',alt:'matw',imgTitle:'' },
    ]
    // const [{ key, isPlaying }, setStartAnimate] = useState({
    //     key: '0',
    //     isPlaying: false,
    // });
    return (
        <div className="w-full bg-[#093484] min-h-[110px] md:min-h-[120px] flex px-2 overflow-x-scroll md:overflow-x-auto">
            <div className="md:mx-auto grid grid-flow-col gap-1 md:min-w-[955px] justify-center">
                {links.map((item, index) => {
                    return <Link key={`links${index}`} to={item.link} className={`min-w-max flex flex-col px-1 justify-center items-center overflow-hidden`}>
                        <img src={item.image} alt={item.alt} title={item.imgTitle} className={`max-w-full w-[56px] md:w-[68px] lg:w-[78px] circle_animation rounded-full`}  />
                        <h3 className='text-center text-white text-[12px] mt-1 whitespace-nowrap'>{item.title}</h3>
                    </Link>
                })}

                {/* <Link
                    onMouseEnter={() => {
                        setStartAnimate({
                            isPlaying: true,
                            key: 'subscription',
                        });
                    }}
                    onMouseLeave={() => {
                        setStartAnimate({
                            isPlaying: false,
                            key: 'subscription',
                        });
                    }}
                    to={'/subscription'} className='flex flex-col px-2 md:px-3 justify-center items-center overflow-hidden w-[93px] md:w-[101px]'>
                    <div className="flex bg-[#093484] border border-[#1c9dda] p-0.5 rounded-full">
                        <div className="max-w-full w-[64px] h-[64px] circle_animation rounded-full bg-[#1c9dda] p-1.5 flex justify-center items-center">
                            <Lottie
                                options={{
                                    animationData: subscribeJson,
                                    autoplay: true,
                                    loop: true,
                                    rendererSettings: {
                                        className: 'ant-menu-item-icon',
                                    },
                                }}
                                isStopped={!(key === 'subscription' && isPlaying)}
                            />
                        </div>
                    </div>
                    <h3 className='text-center text-white text-[12px] mt-1'>Subscribe</h3>
                </Link>
                <Link
                    onMouseEnter={() => {
                        setStartAnimate({
                            isPlaying: true,
                            key: 'phone',
                        });
                    }}
                    onMouseLeave={() => {
                        setStartAnimate({
                            isPlaying: false,
                            key: 'phone',
                        });
                    }}
                    to={'/contact'} className='flex flex-col px-2 md:px-3 justify-center items-center overflow-hidden w-[93px] md:w-[101px]'>
                    <div className="flex bg-[#093484] border border-[#1c9dda] p-0.5 rounded-full">
                        <div className="max-w-full w-[64px] h-[64px] circle_animation rounded-full bg-[#1c9dda] p-3 flex justify-center items-center">
                            <Lottie
                                options={{
                                    animationData: phoneJson,
                                    autoplay: true,
                                    loop: true,
                                    rendererSettings: {
                                        className: 'ant-menu-item-icon',
                                    },
                                }}
                                isStopped={!(key === 'phone' && isPlaying)}
                            />
                        </div>
                    </div>
                    <h3 className='text-center text-white text-[12px] mt-1'>Contact</h3>
                </Link> */}
            </div>
        </div>
    );
}

export default Highlights;
