import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { selectSelectedCurrency } from '../../../store/user';
import { useKeenSlider } from 'keen-slider/react';
import donorImg from '../../../images/home/donor-image.png';
import ShowCurrencies from '../../../utils/showCurrencies';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { startDateOfAppealCampaign } from '../details';

const AppealDonors = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { lang, currency, donors, usedOnTab } = props;
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      mode: 'snap',
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      loop: true,
      breakpoints: {
        '(min-width: 320px)': {
          slides: { perView: 1, spacing: 5 },
        },
        '(min-width: 768px)': {
          slides: { perView: 3, spacing: 5 },
        },
        '(min-width: 992px)': {
          slides: { perView: 5, spacing: 10, origin: 'center' },
        },
        '(min-width: 1280px)': {
          slides: { perView: 7, spacing: 10, origin: 'center' },
        },
      },
      slides: {
        origin: 'center',
        // perView: 4,
        // spacing: 8,
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  // const [donors, setDonors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  // const searchedProducts = [
  //   'MA-PAL-GL-2-001',
  //   'MA-PAL-GL-2-002',
  //   'MA-PAL-GL-2-003',
  //   'MA-PAL-GL-2-004',
  //   'MA-PAL-GL-2-005',
  //   'MA-PAL-GL-2-006',
  //   'MA-PAL-GL-2-007',
  //   'MA-PAL-GL-2-008',
  //   'MA-PAL-GL-2-009',
  //   'MA-PAL-GL-1-001',
  //   'MA-PAL-GL-1-002',
  //   'MA-PAL-GL-1-003',
  //   'MA-PAL-GL-1-004',
  //   'MA-PAL-GL-1-005',
  //   'MA-PAL-GL-1-006',
  //   'MA-PAL-GL-1-007',
  //   'MA-PAL-GL-1-008',
  //   'MA-PAL-GL-1-009',
  //   'MA-PAL-GL-3-001',
  //   'MA-PAL-GL-3-002',
  //   'MA-PAL-GL-3-003',
  //   'MA-PAL-GL-3-004',
  //   'MA-PAL-GL-3-005',
  //   'MA-PAL-GL-3-006',
  // ];
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleDonateTime = value => {
    // var now = moment(new Date().toISOString().split('T')[0], "DD-MM-YYYY HH:mm:ss");
    // var before = moment(value, "DD-MM-YYYY HH:mm:ss");
    var now = moment(new Date());
    var before = moment(new Date(startDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    var timeDifference = now.diff(before, 'minutes');
    // var timeDifference = before.diff(now, "minutes", true);
    let time = '';
    if (timeDifference < 60) {
      time = `${Math.round(timeDifference)} minutes ago`;
    } else if (timeDifference < 1440) {
      time = `${Math.round(timeDifference / 60)} hours ago`;
    } else if (timeDifference < 10080) {
      time = `${Math.round(timeDifference / 1440)} days ago`;
    } else if (timeDifference > 10079) {
      time = `${Math.round(timeDifference / 10080)} weeks ago`;
    }
    return time;
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <section className={`bg-[#F5F5F5] px-4 ${usedOnTab ? 'py-2 md:py-4' : 'py-4 md:py-12'}`}>
      <div className={`w-full justify-center items-center flex flex-col ${usedOnTab ? 'pb-2 md:pb-4' : 'pb-4 md:pb-8'}`}>
        <h2 className={`text-DarkerBlue font-brandingBold ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'}`}>Donors</h2>
        <p
          className={`mt-2 md:mt-4 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${
            lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
          }`}
        >
          Recent Supporters
        </p>
      </div>
      <div className="w-full hidden sm:flex flex-wrap justify-center items-center relative">
        <div ref={sliderRef} className="keen-slider py-3">
          {donors.map((item, index) => {
            return (
              <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                <div className="w-full max-w-[300px] bg-white rounded-md shadow-md p-4 flex justify-start items-center">
                  <div className="flex justify-center items-center w-12 h-12 rounded-full">
                    <img className="w-full rounded-full" src={donorImg} alt="" />
                  </div>
                  <div className="flex flex-col pl-2 md:pl-3">
                    <h2 className="text-sm md:text-base text-DarkerBlue font-brandingBold">{item ? item.donor_name : <Skeleton height={24} />}</h2>
                    <p className={`text-[#78716C] text-xs md:text-sm ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                      {item ? (
                        <>
                          {ShowCurrencies(currency, Math.round(item.amount), true)}, {handleDonateTime(item.created_at)}
                        </>
                      ) : (
                        <Skeleton height={21} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {donors.length > 1 && (
          <div className="flex sm:hidden bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
            {loaded && instanceRef.current && (
              <div className="flex justify-center items-center relative w-full">
                {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={`q${idx}`}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={
                        'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                        (currentSlide === idx ? ' bg-LightBlue hover:opacity-60' : 'bg-[#C3C3C3]')
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-full flex flex-col sm:hidden justify-center relative">
        {donors.map((item, index) => {
          return (
            <div key={`carousel_${index}`} className="w-full mb-1 bg-white rounded-md shadow-md p-4 flex justify-start items-center">
              <div className="flex justify-center items-center w-12 h-12 rounded-full">
                <img className="w-full rounded-full" src={donorImg} alt="" />
              </div>
              <div className="w-full flex justify-between items-center">
                <div className="flex flex-col pl-2 md:pl-3">
                  <h2 className="text-sm md:text-base text-DarkerBlue font-brandingBold">{item ? item.donor_name : <Skeleton height={24} />}</h2>
                  <p className={`text-[#78716C] text-xs md:text-sm ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                    {item ? <>{handleDonateTime(item.created_at)}</> : <Skeleton height={21} />}
                  </p>
                </div>
                <p className={`text-[#78716C] text-sm md:text-md ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                  {item ? <>{ShowCurrencies(currency, Math.round(item.amount), true)}</> : <Skeleton height={21} />}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default AppealDonors;
