import { createAsyncThunk } from '@reduxjs/toolkit';
import { confirmLogin, register } from '../../services/auth';

export const registerUser = createAsyncThunk(
    'auth/register',
    async (userToRegister,{ rejectWithValue }) => {
        try{
          await register(userToRegister);
          return {
            success: true,
            user: userToRegister
          };
        }catch(error){
            if (error.response && error.response.data.data.message) {
              return rejectWithValue(error.response.data.data.message)
            } else {
              return rejectWithValue(error.message)
            }
        }
    }
);

export const userLogin = createAsyncThunk(
    'auth/verify/login',
    async (user, { rejectWithValue }) => {
        try{
            const res = await confirmLogin(user);
            localStorage.setItem('token', res.data?.data?.token)
            return res.data;
        }catch(error){
            if (error.response && error.response.data.message) {
              return rejectWithValue(error.response.data.message)
            } else {
              return rejectWithValue(error.message)
            }
        }
    }
)