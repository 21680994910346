import React from 'react';
import { Link } from 'react-router-dom';
import ZakatWidgetEmbedded from '../../../zakat/zakatWidgetEmbedded';

const ImageBanner = props => {
  const { data } = props;

  return (
    <Link to={data[0]?.url?.value}>
      <div className="relative">
        <img
          src={window.innerWidth > 500 ? data[0]?.image?.value : data[0]?.image?.conversation?.mobile ?? data[0]?.image?.value}
          alt={data[0]?.image?.custom_attributes?.alt_tag}
          title={data[0]?.image?.custom_attributes?.title_tag}
          className="w-full max-h-[600px] object-cover object-center"
        />
        {
          data[0]?.banner_content?.value ? (
            <div className={`absolute translate-y-[-50%] top-[50%] bg-[#F60362] p-[20px] rounded-[15px] max-w-[390px] ${data[0]?.content_position?.value === 'left' ? 'left-[50px]' : 'right-[50px]'}`}>
                <div style={{color: data[0]?.content_text_color?.value}} dangerouslySetInnerHTML={{ __html: data[0]?.banner_content?.value }}></div>
            </div>
          ) : null
        }
        {data[0]?.donation_bar?.value ? (
          <div className="absolute translate-y-[-50%] top-[50%] left-[50px]">
            <ZakatWidgetEmbedded />
          </div>
        ) : null}
      </div>
    </Link>
  );
};

export default ImageBanner;
