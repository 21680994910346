import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gtmLoaded: false,
  eventsQueue: [],
};

const gtmSlice = createSlice({
  name: 'gtm',
  initialState,
  reducers: {
    setGtmLoaded: state => {
      state.gtmLoaded = true;

      state.eventsQueue.forEach(event => {
        window.dataLayer.push(event);
      });
      state.eventsQueue = [];
    },
    pushEventToQueue: (state, action) => {
      const event = action.payload;
      if (state.gtmLoaded) {
        window.dataLayer.push(event);
      } else {
        state.eventsQueue.push(event);
      }
    },
  },
});

export const { setGtmLoaded, pushEventToQueue } = gtmSlice.actions;
export default gtmSlice.reducer;
