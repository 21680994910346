import axios from 'axios';

const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;

let config = {
  headers: {
    "X-user-matw": Header
  },
};

function getBanners() {
  return axios.get(`${Base}/banners`,config);
}
function createBanner(data, file) {
  let config = {
    headers: {
      // accept: '*/*',
      'accept': 'application/json',
      'content-type': file.type,
      'content-length': `${file.size}`,
      "X-user-matw": Header
    },
  };
  console.log("file before send save=",file,data,config)
  return axios.post(`${Base}/banners`, data, config);
  // return axios.post(`${Base}/banners`, data, config);
}
function deleteBanner(id) {
  return axios.delete(`${Base}/banners/${id}`,config);
}
function updateBanner(id,data,file) {
  let config = {
    headers: {
      // accept: '*/*',
      'accept': 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      "X-user-matw": Header
    },
  };
  return axios.post(`${Base}/banners/${id}`, data, config);
}

function updateProduct(id,data,file) {
  let config = {
    headers: {
      'accept': 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      "X-user-matw": Header
    },
  };
  console.log("file before send update=",file,data,config)
  return axios.post(`${Base}/admin-dashboard/products/${id}`, data,config);
  // return axios.patch(`${Base}/admin-dashboard/products/${id}`, data,);
}
function createProduct(data,file) {
  let config = {
    headers: {
      'accept': 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      "X-user-matw": Header
    },
  };
  console.log("file before send update=",file,data,config)
  return axios.post(`${Base}/admin-dashboard/products`, data,config);
}
function createSubCategory(data,file) {
  let config = {
    headers: {
      'accept': 'application/json',
      'content-type': file ? file.type : '',
      'content-length': `${file ? file.size : ''}`,
      "X-user-matw": Header
    },
  };
  return axios.post(`${Base}/admin-dashboard/categories`, data,config);
}

function getPage(pageName) {
  return axios.get(`${Base}/admin-dashboard/page-builder/${pageName}`, config);
}
function getAllPages() {
  return axios.get(`${Base}/admin-dashboard/page-builder`, config);
}
function getUserAllPages() {
  return axios.get(`${Base}/pages`);
}
function createPage(data,file) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  console.log("file before send update=",file,data,config)
  return axios.post(`${Base}/admin-dashboard/page-builder`, data,config);
}
function updatePage(id,data) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.put(`${Base}/admin-dashboard/page-builder/${id}`, data,config);
}
function deletePage(id) {
  let config = {
    headers: {
      'accept': 'application/json',
      'Authorization': 'k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb',
      "X-user-matw": Header
    },
  };
  return axios.delete(`${Base}/admin-dashboard/page-builder/${id}`,config);
}
// function getAllProductBlocks() {
//   return axios.get(`${Base}/admin-dashboard/page-builder`, config);
// }
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBanners,
  createBanner,
  deleteBanner,
  updateBanner,
  updateProduct,
  createProduct,
  createSubCategory,
  getPage,
  getAllPages,
  getUserAllPages,
  createPage,
  updatePage,
  deletePage,
};
