// import { XMarkIcon } from "@heroicons/react/24/outline";
const CookieBanner = (props) => {
    const {
        // isCookieSet,
        // setIsCookieSet,
        // cookies,
        // removeCookie,
        setCookie,
        handleClose } = props;
    return (
        <div className="w-full fixed bottom-0 bg-slate-600 z-50 py-4 px-4">
            {/* <div className="w-full flex justify-end cursor-pointer">
                <XMarkIcon className="text-white w-4 h-4" onClick={() => handleClose()} />
            </div> */}
            <div className="md:container md:mx-auto">
                <div className="flex items-center justify-center md:flex-row flex-col">
                    <p className="text-white text-sm md:text-md lg:text-lg mb-2 md:mb-0 text-center md:text-left">This website uses {' '}
                        <a href="/gdpr/" style={{ fontWeight: 'bold' }}>
                            Cookies
                        </a>{' '}
                        (halal ones) to enhance the user experience.</p>
                    <button
                        onClick={() => { handleClose(); setCookie() }}
                        className="w-full md:w-auto px-8 ml-0 md:ml-6 min-w-[130px] h-12 flex justify-center items-center text-white bg-[#f60362] rounded-md transition-all duration-200 hover:bg-[#00a3da]">
                        I Accept
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CookieBanner;
