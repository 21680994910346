import { getYoutubeVideoId } from '../../../../utils/helperFunctions';

export default function Index({ data }) {
  return (
    <div className="md:container md:mx-auto h-auto my-5">
      <div className={`flex w-full flex-wrap justify-center items-start px-2.5 gap-4`}>
        {data[0].cards?.map((item, index) => (
          <div
            key={index}
            className={`md:basis-[31%] basis-[100%]  w-full col-span-1 border px-4 py-4 bg-white border-[#b8b7b7] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)]  rounded-[10px]`}
          >
            <div className={`${data[0].display_type.value === 'normal' ? 'h-[200px] sm:h-[220px]' : 'h-[360px] sm:h-[600px]'}`}>
              <iframe
                className="video rounded-xl"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${getYoutubeVideoId(item.fields?.link?.value)}?autoplay=0`}
              />
            </div>
            <div className="mt-4">
              <p className=" mb-2 text-left text-[#78716C] text-[16px]">{item?.date}</p>
              <p
                className="text-black font-brandingBold  sm:mb-0 text-lg text-left"
                dangerouslySetInnerHTML={{ __html: item.fields?.name?.value }}
              ></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
