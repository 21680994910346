import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../../store/user';
import ShowCurrencies from '../../../../../utils/showCurrencies';
import SelectCurrency from '../../../../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import ConvertUSD from '../../../../../utils/convertUSD';
import useHandleAddProduct from '../../../../../utils/handleAddProduct';
import { selectProducts } from '../../../../../store/products';
import { useMemo } from 'react';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const DonationWithSuggestionWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, products, currentProduct, setCurrentProduct, data } = props;
  const suggestedPrices = useMemo(() => {
    const { prices } = data[0] ?? {};
    return prices?.value ?? [];
  }, [data]);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [currentCurrency, setCurrentCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  const allProducts = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const handleConvert = (currency, value) => {
    return Math.round(ConvertUSD(currency, value, false));
  };

  const handleSelectedItem = index => {
    let tempSelectedPrice = {};
    tempSelectedPrice.index = index;
    tempSelectedPrice.value = suggestedPrices[index];
    setSelectedPrice(tempSelectedPrice);
  };
  const handleProductAmount = (currency, value) => {
    setProductAmount(handleConvert(currency, value));
  };

  const [selectedPrice, setSelectedPrice] = useState({
    value: suggestedPrices[1],
    index: 1,
  });
  const suggestedRecurringModes = [
    { value: 'one-off', label: 'Single' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(suggestedRecurringModes[0]);

  const handleProductSelect = product => {
    setCurrentProduct(product);
  };
  const handleAddNewProduct = () => {
    rootLoop: for (let item of allProducts) {
      for (let inner_item of item.sub_categories) {
        for (let inner_item_product of inner_item.products) {
          if (inner_item_product.creator === currentProduct.creator) {
            let tempProduct = inner_item_product;
            let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
            tempProduct = Object.assign({}, tempProduct, {
              quantity: customAmount,
              recurring: selectedRecurring.value,
            });
            addProduct(tempProduct, currentCurrency);
            break rootLoop;
          }
        }
      }
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    setSelectedPrice({
      value: suggestedPrices[1],
      index: 1,
    });
  }, [suggestedPrices]);

  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
      selectedPrice ? handleSelectedItem(selectedPrice.index) : handleProductAmount(selectedCurrencyStore, productAmount);
    } else {
      setCurrentCurrency('USD');
      handleSelectedItem(selectedPrice.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <div className="max-w-[1440px] md:mx-auto px-2.5">
      <div className={`w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}>
        <div className="w-full grid md:grid-cols-2 xl:grid-flow-col md:auto-cols-max gap-2 ">
          <div className="flex gap-2">
            <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
            <ReactSelect
              options={products}
              className="max-h-[48px] w-full min-w-[260px]"
              value={currentProduct}
              isSearchable={false}
              styles={styles}
              placeholder="Select Product"
              onChange={e => handleProductSelect(e)}
            />
          </div>

          <div className="flex gap-2">
            <ReactSelect
              options={suggestedRecurringModes}
              className="[&>*]:!cursor-pointer w-full"
              value={selectedRecurring}
              isSearchable={false}
              styles={styles}
              onChange={e => {
                setSelectedRecurring(e);
              }}
            />
            {suggestedPrices.map((price, index) => {
              return (
                <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                  <div
                    className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] rounded-md ${
                      selectedPrice && selectedPrice.value === price
                        ? 'bg-[#F60362] text-white'
                        : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                    }`}
                    onClick={() => {
                      handleSelectedItem(index);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, price, true)}
                  </div>
                </div>
              );
            })}
          </div>
          <input
            value={productAmount}
            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
            type="number"
            onChange={e => {
              setProductAmount(e.target.value);
              setSelectedPrice(null);
            }}
            placeholder={'Other amount'}
          />
          <button
            disabled={currentProduct === null || (productAmount === '' && selectedPrice === null)}
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md bg-[#f60362] disabled:bg-stone-300 disabled:cursor-not-allowed`}
            onClick={handleAddNewProduct}
          >
            Quick Donate
          </button>
        </div>
      </div>
    </div>
  );
};

export default DonationWithSuggestionWidget;
