import React, { useEffect, useState } from 'react';
import { GlobalDebug } from './utils/removeConsole';
import AllRoutes from './routes';
import GoogleTagManager from './utils/googleTagManager';
import { selectSelectedCurrency, setCountry, setSelectedCurrencyInStore } from './store/user';
import { useDispatch, useSelector } from 'react-redux';
import { setGtmLoaded } from './store/gtm';
import { store } from './store/store';
import { getCurrencies } from './services/content';
import { useCookies } from 'react-cookie';
import CookieBanner from './components/general/cookieBanner';

const App = () => {
  const dispatch = useDispatch();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [cookies, setCookie, removeCookie] = useCookies(['matw']);
  const [isCookieSet, setIsCookieSet] = useState(false);
  const [show, setShow] = useState(false);

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    (process.env.APP_ENV === 'production' || process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false);
    GoogleTagManager(window.location.href);

    if (!window.location.pathname.includes('checkout')) {
      const promises = [];
      promises.push(getCurrencies());
      promises.push(fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze&fields=currency,countryCode,timezone,country').then(res => res.json()));
      Promise.all(promises).then(([currenciesResponse, ipApiResponse]) => {
        const {
          data: { data: currencies },
        } = currenciesResponse;
        const currencyList = currencies?.items?.map(currency => currency.code) ?? [];
        if (!selectedCurrencyStore) {
          dispatch(setSelectedCurrencyInStore(currencyList.includes(ipApiResponse.currency) ? ipApiResponse.currency : 'USD'));
          if (ipApiResponse.timezone.includes('Europe') && ipApiResponse.countryCode !== 'GB') {
            dispatch(setSelectedCurrencyInStore(currencyList.includes('EUR') ? 'EUR' : 'USD'));
          }
        }
        dispatch(setCountry(ipApiResponse.country === 'United Kingdom' ? 'uk' : ''));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId;
    /**
     * The function `checkGtmLoaded` checks if Google Tag Manager (GTM) has been loaded on the page.
     */
    const checkGtmLoaded = () => {
      if (window.dataLayer && window.dataLayer.some(event => event.event === 'gtm.js') && window.dataLayer.find(element => element['gtm.start'])) {
        store.dispatch(setGtmLoaded());
        clearInterval(intervalId);
      }
    };

    // Check if GTM has been loaded every 100ms
    intervalId = setInterval(() => {
      checkGtmLoaded();
    }, 100);

    checkCookie();

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  /* -------------------------------------------------------------------------- */

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkCookie = () => {
    const tempCookie = cookies.matw === 'true';
    if (tempCookie) {
      setIsCookieSet(true);
    } else {
      handleShow();
    }
  };

  const handleSetCookie = () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 75000*36000;
    now.setTime(expireTime);
    setCookie('matw', 'true', {expires: now});
  };

  return (
    <>
      {/* {show && (
        <CookieBanner
          isCookieSet={isCookieSet}
          setIsCookieSet={setIsCookieSet}
          cookies={cookies}
          setCookie={handleSetCookie}
          removeCookie={removeCookie}
          handleClose={handleClose}
        ></CookieBanner>
      )} */}
      <AllRoutes />
    </>
  );
};

export default App;
