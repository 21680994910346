import React from 'react';
const ToDoBox = (props) => {
    return <div className={`flex flex-col justify-center items-center ${props.isLast ? 'mb-2' : 'mb-16'} lg:mb-0 p-4 lg:p-8 relative rounded-lg border-2 border-[#14A2DC] col-span-12 sm:col-span-6 md:col-span-4 shadow-lg h-full`}>
        <div className="rounded-full absolute -top-10 md:-top-12 flex justify-center items-center text-4xl md:text-6xl text-white bg-[#14A2DC] border-2 border-white w-20 h-20 md:w-24 md:h-24">{props.number}</div>
        <h3 className=" text-[#14A2DC] text-xl md:text-2xl text-center mt-12 font-medium">
            {props.title}
        </h3>
        <p className=" text-[#78716C] mt-4 md:mt-8 text-center">
            {props.description}
        </p>
    </div>
}

export default ToDoBox;
