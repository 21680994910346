import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { decodeHtml } from '../../../../utils/decodeHTML';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export default function Card({ card }) {
  const [isOverflow, setIsOverflow] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleHeight = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    card?.content?.value?.length > 85 ? setIsOverflow(true) : setIsOverflow(false);
  }, [card]);

  return (
    <div className="basis-1/2 sm:basis-1/3 lg:basis-1/4 p-2 ">
      <div className="w-full p-2 pb-4 rounded-xl shadow-lg xl:h-[437px] lg:h-[381px] sm:h-[275px] h-[275px] flex flex-col justify-between bg-white cursor-pointer">
        <Link
          to={card.button.action.url}
          className="overflow-hidden rounded-md flex justify-center items-center md:w-[218px] md:h-[218px] lg:w-[224px] lg:h-[224px] xl:w-[288px] xl:h-[288px] 2xl:w-[352px] 2xl:h-[352px]"
        >
          {card?.image?.value ? (
            <LazyLoadImage
              alt={card?.image?.custom_attributes?.alt_tag}
              title={card?.image?.custom_attributes?.title_tag}
              effect="blur"
              width={`100%`}
              delayTime={500}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              delayMethod="debounce"
              src={window.innerWidth > 500 ? card?.image?.value : card?.image?.conversation?.mobile ?? card?.image?.value}
            />
          ) : (
            <img className="w-full" src="/images/general/logo-replace.png" alt="" />
          )}
        </Link>
        <h3 className="text-[#00a3da] capitalize text-md md:text-xl lg:text-2xl text-center leading-4 my-2 cursor-pointer transition-all duration-200 hover:text-[#3189c0]">
          {card?.title?.value}
        </h3>
        {card?.content?.value && (
          <>
            <div
              className={`text-center my-2 !text-stone-400 text-sm sm:text-sm md:text-base lg:text-md overflow-y-hidden transition-all duration-200 ${
                showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[79px]'
              }`}
              dangerouslySetInnerHTML={{ __html: decodeHtml(card?.content?.value) }}
            />
            <div className="flex h-8 pb-1 bg-white">
              {isOverflow && (
                <ChevronDownIcon
                  onClick={() => handleHeight()}
                  className={`w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${showMore ? ' transform rotate-180' : ''}`}
                />
              )}
            </div>
          </>
        )}
        <div className="flex justify-center mt-4">
          <Link
            to={`/${card.button.action.url}`}
            style={{ backgroundColor: card.button?.style?.color ? card.button?.style?.color : '#f60362' }}
            className={`capitalize w-8/12 text-white text-[12px] text-center md:text-[14px] col-span-6 xl:col-span-4 xl:col-start-2 flex rounded-md transition-all duration-200 hover:bg-[#00a3da!important] mr-2 cursor-pointer px-2 py-2 justify-center items-center`}
          >
            {card.button.value}
          </Link>
        </div>
      </div>
    </div>
  );
}
