import React from 'react';
import { CustomButton } from '../../../../components/general';
import { fontTypeMatcher, getYoutubeVideoId } from '../../../../utils/helperFunctions';

const direction = {
  left: 'row',
  right: 'row-reverse',
  top: 'column',
  bottom: 'column-reverse',
};

const getMedia = (media, type, alt, title) => {
  switch (type) {
    case 'image':
      return <img src={media} alt={alt} title={title} className="rounded-lg" />;
    case 'video':
      const videoId = getYoutubeVideoId(media);

      return (
        <iframe
          className="video"
          width={'100%'}
          height={'400px'}
          title={'youtube'}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://youtube.com/embed/${videoId}?autoplay=0`}
        ></iframe>
      );
    default:
      return null;
  }
};

function getMobileOrWebImage(data) {
  return window.innerWidth > 500 ? data?.image?.value : data?.image?.conversation?.mobile ?? data?.image?.value;
}

const ImageCard = ({ data }) => {
  const rowDirection = direction[data[0].image_position?.value];
  const mediaType = data[0]?.flexi_type?.value ?? (data[0].image ? 'image' : null);
  const mediaSrc = mediaType === 'video' ? data[0]?.video_url?.value : getMobileOrWebImage(data[0]);
  const backgroundColor = data[0]?.background_color?.value;
  const titleFont = data[0]?.title_text_type?.value;

  return (
    <div className="py-5" style={{ backgroundColor }}>
      <div className="md:container md:mx-auto mt-5 lg:mb-4 ">
        <div className="basis-full px-2.5 flex gap-x-10 flex-wrap lg:flex-nowrap items-center" style={{ flexDirection: rowDirection }}>
          <div className="basis-full lg:basis-1/2">
            {getMedia(mediaSrc, mediaType, data[0].image?.custom_attributes?.alt_tag, data[0].image?.custom_attributes?.title_tag)}
          </div>
          <div className="basis-full lg:basis-1/2 flex justify-center lg:items-start items-center flex-col">
            <p
              dangerouslySetInnerHTML={{ __html: data[0].title?.value }}
              className={`text-[#253B7E] text-[25px] leading-[25px] my-4 ${fontTypeMatcher(titleFont)}`}
            ></p>
            <p
              dangerouslySetInnerHTML={{ __html: data[0].content?.value }}
              className="text-[#78716C] leading-[19px] sm:leading-6 md:text-[18px] lg:text-start text-center font-brandingMedium"
            ></p>
            <div className={`flex w-full lg:justify-${data[0].button?.style?.position === 'right' ? 'end' : 'start'} justify-center`}>
              <div className={` ${data[0].button?.style?.visible ? '' : 'hidden'} flex items-center flex-col sm:flex-row mt-6`}>
                <a href={data[0]?.button?.action?.url}>
                  <CustomButton
                    title={data[0].button.value}
                    style={{ ...data[0].button.style, color: 'white' }}
                    className="mb-2 sm:mb-0 min-w-[226px] font-montserratMedium "
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
