// import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
//import ConvertUSD from '../../utils/convertUSD';
import ShowCurrencies from '../../utils/showCurrencies';
import useHandleAddProduct from '../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ConvertAUD from '../../utils/convertAUD';
import CustomButton from './button';
import { useDispatch } from 'react-redux';
import { store } from '../../store/store';
import { pushEventToQueue } from '../../store/gtm';

const FundraiserProductBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const refImg = useRef(null);
  const refLazyImg = useRef(null);
  const { product, currency, goal, hideStatus, disableOnClick = false, onClick } = props;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  // const [isAnyAmount, setIsAnyAmount] = useState(false);
  const [addProduct] = useHandleAddProduct();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  // const getConvertedCurrency = async (amount) => {
  //     let tempFutureProducts = [];
  //     try {
  //         const response = await services.convertCurrency(amount);
  //         console.log('getConvertedCurrency=', response);
  //     } catch (error) {
  //         console.error('error========', error);
  //     }
  // };
  const handleCustomProductAmount = product => {
    if (Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) || product.id === 52 || product.id === 69) {
      // setIsAnyAmount(true);
    } else {
      // let hasQuantity = product.quantity
      //     ? product.quantity > 1
      //         ? true
      //         : false
      //     : false
      // if (Number(product[currency.toLowerCase()]) < 3 && !hasQuantity) {
      //     setIsAnyAmount(true);
      // } else {
      //     setIsAnyAmount(false);
      // }
    }
    // getConvertedCurrency(product.raised)
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };
  const handleViewItemDataLayer = (tempProduct, currency) => {
    let dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    store.dispatch(pushEventToQueue(dataLayerTemp));
  };
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    product && handleCustomProductAmount(product);
    product && ref.current.clientHeight > 80 && setIsOverflow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full rounded-xl- shadow-lg bg-white overflow-hidden">
      <div
        ref={refImg}
        className="overflow-hidden rounded-md- max-h-[336px]- w-full sm:w-[348px] sm:h-[348px] md:w-[240px] md:h-[240px] lg:w-[325px] lg:h-[325px] xl:w-[410px] xl:h-[410px] 2xl:w-[368px] 2xl:h-[368px]"
      >
        {product ? (
          product.image_link ? (
            <LazyLoadImage
              ref={refLazyImg}
              alt={product.alt ? product.alt : product.name}
              effect="blur"
              width={`100%`}
              delayTime={500}
              afterLoad={() => {
                handleViewItemDataLayer(product, currency);
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              src={product.image_link} // use normal <img> attributes as props
            />
          ) : (
            <img className="w-full" src="/images/general/logo-replace.png" alt="" />
          )
        ) : (
          <Skeleton height={344} />
        )}
      </div>
      <div className="p-2 md:p-3 md:pb-5">
        <h3 className="text-[#252A34] text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center font-medium h-[34px] sm:h-[32px] lg:h-[48px]  my-2 leading-4 md:leading-none">
          {product ? product.name : <Skeleton height={48} />}
        </h3>
        <div className="relative">
          {product ? (
            <>
              <div
                className={`text-center my-2 relative overflow-hidden ${showMore ? 'h-auto' : 'max-h-[60px] md:max-h-[80px]'} ${
                  product.description.split(' ').length > 28 ? 'mb-4' : 'mb-4'
                }`}
              >
                <p ref={ref} className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]`}>
                  {product.description}
                </p>
              </div>
              {isOverflow && (
                <span className="text-[#00a3da] block w-full absolute cursor-pointer -bottom-5 text-center" onClick={() => handleHeight()}>
                  {showMore ? 'Show less' : 'Show more'}
                </span>
              )}
            </>
          ) : (
            <Skeleton count={4} />
          )}
        </div>
        {!hideStatus && (
          <div className="basis-full flex-col md:basis-1/2 flex justify-center pt-2 md:pt-4">
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-[#78716C]">Donation</p>
              <p className="text-sm text-[#78716C]">
                {handlePercentage(ConvertAUD(currency, product.raised, false), ConvertAUD(currency, goal, false))}
              </p>
            </div>
            <div className="flex w-full h-2 bg-[#F60362] bg-opacity-10 mb-2">
              <div
                className={`bg-[#F60362]`}
                style={{ width: `${handlePercentage(ConvertAUD(currency, product.raised, false), ConvertAUD(currency, goal, false))}` }}
              ></div>
            </div>
            <div className="flex flex-wrap w-full">
              <p className="text-sm text-[#78716C] basis-full lg:basis-1/2">
                <span className="w-[46px] inline-block lg:w-auto">Raised:</span>{' '}
                {ShowCurrencies(currency, ConvertAUD(currency, product.raised, false))}
              </p>
              <p className="text-sm text-[#78716C] basis-full lg:basis-1/2 text-left lg:text-right">
                <span className="w-[46px] inline-block lg:w-auto">Goal:</span> {ShowCurrencies(currency, ConvertAUD(currency, goal, false))}
              </p>
            </div>
          </div>
        )}
        <div className="basis-full flex justify-center items-center mt-8">
          {product ? (
            // ? <Link to={''}
            //     className='text-white text-sm- md:text-lg flex transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mr-2 cursor-pointer px-2 py-2 justify-center items-center'
            //     // onClick={(e) => { e.preventDefault(); handleAddNewProduct() }}
            //     onClick={(e) => { e.preventDefault(); addProduct(product, currency) }}
            // >
            //     Donate Now
            // </Link>
            <CustomButton
              onClick={() => {
                disableOnClick ? onClick() : addProduct(product, currency);
              }}
              title={'Donate Now'}
              className={``}
            />
          ) : (
            <Skeleton height={43} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FundraiserProductBox;
