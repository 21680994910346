/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/other/notFound';
import RouteContext from './context/route';
import ScrollToTop from './utils/ScrollToTop';
import UkModal from './components/general/UkModal';
import CheckoutPage from './pages/checkout';
import DetailsProduct from './pages/products/details';
import SuccessPage from './pages/other/success';
import CancelPage from './pages/other/cancel';
import Contact from './pages/contact';
import FundraisingLandingPageUAT from './pages/fundraising/index-uat';
import LoveOnesLandingPage from './pages/loved-ones-legacy';
import NewVolunteerPage from './pages/volunteer';
import ZakatCalculatorUATLandingPage from './pages/zakat/zakatCalculatorUAT';
import ReceiptPage from './pages/receipt/receipt';
import OrphanEducationLandingPage from './pages/orphans/orphan-education';
import OrphanSupportLandingPage from './pages/orphans/orphan-support';
import MATWAppealDetailsLandingPage from './pages/MATWAppeal/details';

import { useSelector } from 'react-redux';
import { selectSelectedCountry } from './store/user';
import { useEffect } from 'react';
import DynamicPage from './pages/dynamic';

let startTime = null; // Variable to store the start time
const AllRoutes = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [routeState, setRouteState] = useState([{ name: 'MATW Home', id: '/' }]);
  const [showModal, setShowModal] = useState(false);
  const isAlreadyShown = JSON.parse(localStorage.getItem('isModalShown')) || false;
  const country = useSelector(selectSelectedCountry);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('isModalShown', 'true');
  };
  const checkDayAndShowDialog = () => {
    if (window.location.hostname === 'matwproject.org.uk') {
      return true;
    } else {
      return false;
    }
  };
  // Function to track scrolling and calculate scroll distance
  const trackScroll = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    if (dayOfWeek !== 5) localStorage.setItem('isModalShown', 'false');
    if (dayOfWeek === 5 && checkDayAndShowDialog() && country === 'uk') {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const mints = new Date(elapsedTime).getMinutes();
      const secs = new Date(elapsedTime).getSeconds();
      if (secs > 20 || mints > 0) {
        setShowModal(true);
        window.removeEventListener('scroll', trackScroll);
      }
    } else {
      window.removeEventListener('scroll', trackScroll);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    startTime = Date.now();
    checkDayAndShowDialog(); //only for Uk domain
    window.addEventListener('scroll', trackScroll);
    return () => {
      window.removeEventListener('scroll', trackScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  /* -------------------------------------------------------------------------- */

  return (
    <RouteContext.Provider value={{ routeState, setRouteState }}>
      {showModal && !isAlreadyShown && <UkModal closeModal={closeModal} />}
      <Routes>
        <Route path="/" element={<DynamicPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/receipt" element={<ReceiptPage />} />

        {/* <Route path="/otp" element={<Otp />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} /> */}
        <Route path="/order/stripe/pay" element={<Navigate to="/success" replace />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/zakat-calculator" element={<ZakatCalculatorUATLandingPage />} />
        <Route path="/fundraising" element={<FundraisingLandingPageUAT />} />
        <Route path="/loved-ones-legacy" element={<LoveOnesLandingPage />} />
        <Route path="/volunteer" element={<NewVolunteerPage />} />
        <Route path="/appeals/:slug" element={<DetailsProduct />} />
        <Route path="/orphans/education" element={<OrphanEducationLandingPage />} />
        <Route path="/orphans/support-an-orphan" element={<OrphanSupportLandingPage />} />
        <Route path="/appeals/palestine-emergency" element={<MATWAppealDetailsLandingPage campaign="palestine" />} />
        <Route path="/appeals/palestine-refugee-resettlement" element={<MATWAppealDetailsLandingPage campaign="egyptian" />} />

        <Route path="/:alias" element={<DynamicPage />} />
        <Route path="/:alias/:subAlias" element={<DynamicPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </RouteContext.Provider>
  );
};

export default AllRoutes;
