import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ReactSelect from "react-select";
import ZakatSelectCurrency from "./zakatSelectCurrency";
import ShowCurrencies from "../../utils/showCurrencies";
import useHandleAddProduct from "../../utils/handleAddProduct";
// import { selectProducts } from "../../store/products";
import services from "../../services";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const ZakatWidgetEmbedded = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    // const products = useSelector((state) => selectProducts(state));
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedRecurringMode, setSelectedRecurringMode] = useState('one-off');
    const [selectedPrice, setSelectedPrice] = useState(100);
    const [selectedProduct, setSelectedProduct] = useState({
        creator: "MKD-GEN-MED-2023-01-122",
        label: "General Orphan Support",
        value: 124
    });
    const [addProduct] = useHandleAddProduct();
    const suggestedPrices = [
        50, 100, 150
    ]
    const suggestedRecurringModes = [
        { lable: 'Single', value: 'one-off' },
        { lable: 'Weekly', value: 'week' },
        { lable: 'Monthly', value: 'month' }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = async () => {
        setIsLoaded(true);
        try {
            const response = await services.allProducts();
            let tempMakeDonationProducts = [];
            let tempProductsList = [];
            response.data.map(item => {
                item.sub_categories.map(inner_item => {
                    let tempSubCat = inner_item;
                    tempSubCat.products = inner_item.products.filter(inner_item_product => {
                        if (Number(inner_item_product.status) === 1) {
                            if (inner_item_product.creator === "MKD-GEN-MED-2023-01-122") {
                                setSelectedProduct(inner_item_product)
                            }
                            if (inner_item.name === 'Earthquake Appeal') {
                                if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Morocco Appeal
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                                if (inner_item_product.creator === 'EMR-DIS-AFG-2023-02') { // Afghanistan Earthquake Appeal
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                            };
                            if (inner_item.name === 'Libya Floods') {
                                if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Libya Floods
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                            };
                            if (inner_item.name === 'Winter Campaign') {
                                if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') { // Keep Orphans Warm Where Most Needed
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                            };
                            if (inner_item.name === 'Make A Donation') {
                                tempMakeDonationProducts.push(inner_item_product);
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                            }
                        }
                        return inner_item_product;
                        // return inner_item_product;
                    });
                    return tempSubCat;
                });
                return item;
            });
            // console.log("deactive products=",tempDeactiveProducts)
            // setAllProducts(tempProducts);
            setProductsList(tempProductsList);
            setAllProducts(tempMakeDonationProducts)
            // setProductsList(tempProductsList);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value)
        // selectedCurrency(currency);
    }
    const handleProduct = (value) => {
        // console.log("product", value, selectedProduct);
        allProducts.map(item => item.id === value.value && setSelectedProduct(item))
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurringMode,
        })
        addProduct(tempProduct, currentCurrency)
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        getAllProducts();
    }, [])
    // useEffect(() => {
    //     products.length && !isLoaded && getAllProducts();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [products])
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`max-w-[380px] mx-auto bg-[#E2EFF3] rounded-lg p-3 md:p-5 flex items-center`}>
            <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {suggestedRecurringModes.map((mode, index) => {
                        return <div key={`modes${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedRecurringMode === mode.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedRecurringMode(mode.value);
                                    setProductAmount('');
                                }}
                            >
                                {mode.lable}
                            </div>
                        </div>
                    })}
                </div>
                <div className="col-span-12">
                    <ReactSelect
                        options={productsList}
                        className='max-h-[48px]'
                        defaultValue={selectedProduct}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => handleProduct(e)}
                    />
                </div>
                {<div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {suggestedPrices.map((price, index) => {
                        return <div key={`prices${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedPrice(price);
                                    setProductAmount('');
                                }}
                            >
                                {ShowCurrencies(currentCurrency, price, false)}<span className={`uppercase ml-1 text-base`}>{currentCurrency}</span>
                            </div>
                        </div>
                    })}
                </div>}
                <div className="col-span-12 flex flex-wrap">
                    <div className="flex justify-center items-center mr-2 bg-white text-[#F60362] rounded-md w-12 h-12" style={styles.shadow}>
                        {currentCurrency}
                    </div>
                    <div className="flex justify-center items-center mr-2 bg-white text-[#F60362] rounded-md w-12 h-12" style={styles.shadow}>
                        <ZakatSelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                    </div>
                    <div className="flex justify-center items-center bg-white text-[#F60362] rounded-md w-[calc(100%_-_112px)] h-12 px-2" style={styles.shadow}>
                        <input
                            value={productAmount}
                            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                                setSelectedPrice(0);
                            }}
                            placeholder="Enter Amount" />
                    </div>

                </div>
                <div className="col-span-12">
                    <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-[38px] px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={(e) => { handleAddNewProduct() }}
                    >Donate Now</button>
                </div>
                <div className="col-span-12 text-[#6C6B6A] text-center">
                    We accept all major cards and PayPal
                </div>
            </div>
        </div>
    );
}

export default ZakatWidgetEmbedded;
