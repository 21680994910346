import React from 'react';
import Card from './card';
import { fontTypeMatcher } from '../../../../utils/helperFunctions';
import { decodeHtml } from '../../../../utils/decodeHTML';

const ImageContentCardWithUrl = props => {
  const { data } = props;
  const title = data[0]?.title?.value ?? '';
  const cards = data[0]?.cards ?? [];
  const titleFont = data[0]?.title_text_type?.value;
  const content = data[0]?.content?.value;

  return (
    <div className="md:container md:mx-auto mt-5 px-4 md:px-2 lg:px-0">
      <p
        className={`text-center leading-[30px] sm:leading-[55px] text-2xl sm:text-3xl md:text-[40px] text-[#253b7e] py-2 md:py-8 ${fontTypeMatcher(
          titleFont,
        )}`}
      >
        {title}
      </p>
      <div
          className="md:leading-7 text-[#78716C] text-center font-['Montserrat'] text-md lg:text-lg px-4 sm:px-20 md:px-30 lg:px-32 mb-8"
          dangerouslySetInnerHTML={{ __html: decodeHtml(content) }}
        />
      <div className="flex justify-center flex-wrap">
        {cards.map(({ fields: card }, index) => (
          <Card key={index} card={card} />
        ))}
      </div>
    </div>
  );
};

export default ImageContentCardWithUrl;
