// import stripeServices from './stripe';
import userServices from './user';
import paypalServices from './paypal';
import productsServices from './products';
import dashboardServices from './dashboard';
import reportsServices from './reports';
import cryptoServices from './crypto';
import contentServices from './content';
import ipCurrencyServices from './ip-currency';
// import dashboardServices from './dashboard';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...ipCurrencyServices,
  ...userServices,
  ...paypalServices,
  ...contentServices,
  ...productsServices,
  ...dashboardServices,
  ...reportsServices,
  ...cryptoServices,
};
