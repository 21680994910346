import { useEffect, useState } from 'react';
import Accordion from '../../../../components/general/accordion';
import CustomAccordion from '../../../../components/general/customAccordion';
import { getFaqs } from '../../../../services/content';

export default function Index({ data }) {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getFaqs(data[0]?.faq?.request?.url);
        if (res.status === 200) {
          setFaqs(res.data?.data?.rows);
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
  }, [data]);

  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
      <div className={`mt-6 [&_*]:!font-[AlmariMedium]`}>
        <CustomAccordion.Accordion className={'!font-[AlmariMedium]'} items={faqs} />
      </div>
    </section>
  );
}
