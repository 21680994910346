import React, { useEffect, useState } from 'react';
import DonationWithSuggestionWidget from './with-suggestion';
import DonationWidget from './default';
import services from '../../../../services';

const DonationFormWidget = ({ data, layout }) => {
  const hasSuggestions = data[0]?.type?.value === 'fixedPrice';
  const defaultProduct = layout.defaultProduct;
  const hasDefaultProduct = !!defaultProduct?.request?.url;
  const [currencies, setCurrencies] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(defaultProduct);

  const getContent = () => {
    const getCurrencyPromise = services.getContent(data[0]?.currency?.request?.url, true);
    const getProductsPromise = services.getContent(data[0]?.product?.request?.url);
    const promises = [getCurrencyPromise, getProductsPromise];
    if (defaultProduct?.request?.url) {
      promises.push(services.getContent(defaultProduct.request.url));
    }
    Promise.all(promises)
      .then(res => {
        const currencyRes = res[0];
        const productRes = res[1];
        const defaultProductRes = res[2];
        if (currencyRes.status === 200) {
          const currencyList = currencyRes.data.data.items.map(c => ({
            value: c.name,
            label: (
              <span className="flex items-center">
                <img src={c.flag} alt="flag" className="w-5 mr-1" />
                {c.name}
              </span>
            ),
          }));
          setCurrencies(currencyList);
        }
        if (productRes.status === 200) {
          setProductsList(productRes.data.data);
          const productList = productRes.data.data.map(item => ({ value: item.id, label: item.name, creator: item.creator }));
          setProducts(productList);
          setCurrentProduct(productList[0]);
        }
        if (defaultProductRes?.status === 200) {
          const [product] = defaultProductRes.data?.data || [];
          if (product) setCurrentProduct({ value: product.id, label: product.name, creator: product.creator });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const props = {
    products,
    currencies,
    productsList,
    currentProduct,
    setCurrentProduct,
  };

  useEffect(() => {
    getContent();
  }, []);

  if (hasSuggestions) {
    return <DonationWithSuggestionWidget {...props} data={data} />;
  }
  return <DonationWidget {...props} data={data} />;
};

export default DonationFormWidget;
