import { Link } from 'react-router-dom';
import logoAcnsImg from './../../images/general/logo-acns.png';
import logoCardsImg from './../../images/general/logo-cards.png';
import logoMatwPolicyImg from './../../images/general/logo-matw-policy.png';
import { useEffect, useState } from 'react';
import services from '../../services';
const MainFooter = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const [menuItems, setMenuItems] = useState([]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getMenuItems = async () => {
    const {
      data: { data },
    } = await services.getContent('/menus/footer');
    setMenuItems(data.items);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getMenuItems();
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="w-full bg-gradient-to-r from-[#00a3da] to-[#093484] px-2 py-6">
        <div className="md:container mx-auto grid grid-cols-12 px-2 gap-2 md:gap-4">
          {menuItems.map(item => (
            <div key={item.id} className="col-span-12 sm:col-span-6 lg:col-span-3 flex flex-col justify-between">
              <div>
                <h4 className="text-white font-bold text-2xl pt-2 pb-4">{item.title}</h4>
                <ul className="flex flex-col">
                  {item.children.map(child => (
                    <li key={child.id} className="w-full transition-all duration-200 hover:pl-1 py-1">
                      <Link className="text-[#c6e9ff]" to={child.url}>
                        {child.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          <div className={`col-span-12 sm:col-span-6 lg:col-span-3 flex flex-col justify-between`}>
            <ul className="flex flex-col">
              <li className="w-full pt-2 pb-4">
                <span className="text-white font-bold text-[20px]">Bank Details</span>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <span className="text-[#c6e9ff]">Bank: CAF Bank</span>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <span className="text-[#c6e9ff]">Account Name: MATW PROJECT</span>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <span className="text-[#c6e9ff]">Account Number: 00035950</span>
              </li>
              <li className="w-full transition-all duration-200 hover:pl-1 py-1">
                <span className="text-[#c6e9ff]">Sort Code: 40-52-40</span>
              </li>
            </ul>
            <div>
              <img
                src={logoMatwPolicyImg}
                alt="MATW Project"
                className="mb-3 w-[260px] h-[50px] sm:w-[280px] sm:h-[52px] md:w-[335px] md:h-[63px] lg:w-[207px] lg:h-[38px] xl:w-[271px] xl:h-[51px] 2xl:w-[335px] 2xl:h-[63px]"
              />
              <img
                src={logoCardsImg}
                alt="MATW Project"
                className="max-w-full w-[260px] h-[35px] sm:w-[280px] sm:h-[36px] md:w-[335px] md:h-[40px] lg:w-[207px] lg:h-[30px] xl:w-[271px] xl:h-[32px] 2xl:w-[335px] 2xl:h-[40px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#282828] py-6 px-4 lg:px-12 w-full">
        <p className="text-center text-[#aaa] text-lg copy-right">
          MATW Project UK is a UK registered charity (1201110)
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" className="w-[14px] h-[14px] inline-block mx-1">
            <path
              fillRule="evenodd"
              clipRule={'evenodd'}
              fill="#aaa"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7,0c3.9,0,7,3.2,7,7s-3.1,7-7,7s-7-3.1-7-7S3.2,0,7,0z M7,1C3.7,1,1,3.7,1,7s2.7,6,5.9,6s6-2.7,6-6S10.3,1,7,1
            z M10.7,9.5c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2C9,9.6,8.1,10.2,7,10.2c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
            c1.1,0,2,0.5,2.6,1.4c0.2,0.3,0.6,0.4,0.9,0.2s0.4-0.6,0.2-0.9c-0.8-1.2-2.2-2-3.7-2c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
            C8.5,11.5,9.9,10.7,10.7,9.5z"
            />
          </svg>
          2023 and registered office at 275 Featherstall Road North OLDHAM OL1 2NJ
        </p>
      </div>
    </>
  );
};

export default MainFooter;
