import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { selectSelectedCurrency } from '../../../store/user';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import AppealGivingProductBox from './appealGivingProductBox';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import useHandleAddProduct from '../../../utils/handleAddProduct';
// import Sticky from 'react-sticky-el';
// import CustomButton from '../../../components/general/button';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
// import { useNavigate } from 'react-router-dom';
// import { CloseIcon } from '../../../components/general/customIcons';

const AppealLevelGiving = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { givingProducts, lang = 'en', showAllBoxes = false, title,
    // isDetailsPage = false,
    campaign,
    // handleAddQty, handleReduceQty, handleAddToGiving,
    handleSelectedGivingProps } = props;
  let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  // const navigate = useNavigate();
  // const [basketStates, setBasketStates] = useState(basketStatesFromLocalStorage);
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  // const [isMinimized, setIsMinimized] = useState(false);
  // const [showMoreItems, setShowMoreItems] = useState(false);

  // const [products] = useState(givingProducts);
  const [products, setProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  // const [selectedGivingProducts, setSelectedGivingProducts] = useState({
  //   products: [],
  //   total: 0,
  // });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: 1.3, spacing: 20 },
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 3, spacing: 10 },
      },
      '(min-width: 1280px)': {
        slides: { perView: 4, spacing: 10 },
      },
    },
    slides: {
      origin: 'center',
      // perView: 4,
      // spacing: 8,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  // const handleCurrency = (currency, tempBasket) => {
  //   if (tempBasket.products.length && tempBasket.products[0].product) {
  //     tempBasket.products.map(item => {
  //       item.currency = currency;
  //       item.product.currency = currency;
  //       item.total = item.quantity * item.product[currency];
  //       item.amount = item.product[currency];
  //       return item;
  //     });
  //     tempBasket.currency = currency;
  //     HandleLocalStorage(tempBasket);
  //   } else {
  //     tempBasket.currency = currency;
  //     HandleLocalStorage(tempBasket);
  //   }
  // };
  const handleAddQty = (prd, index) => {
    let tempPrd = Object.assign({}, prd, {
      quantity: 1,
    });
    // addProduct(tempPrd, selectedCurrency, 'single', false, true);
    addProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true })
    handleSelectedGiving();
  };
  const handleReduceQty = (prd, index) => {
    if (prd.quantity > 1) {
      let tempPrd = Object.assign({}, prd, {
        quantity: -1,
      });
      editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true })
      handleSelectedGiving();
    } else {
      handleRemoveProduct(prd)
    }
  };
  const handleAddToGiving = (prd, index) => {
    let tempPrd = Object.assign({}, prd, {
      quantity: 1,
    });
    addProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true })
    handleSelectedGiving();
  };
  const handleRemoveProduct = (prd) => {
    removeProduct(prd)
    handleSelectedGiving();
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];
    // let tempSelectedGiving = [];
    // let tempTotalGiving = 0;
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
            // tempTotalGiving += (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
            // tempSelectedGiving.push(item);
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    // tempSelectedGiving.length === 1 && setIsMinimized(false);
    // setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
    console.log("set products=",temp)
    temp = temp.map(item => item).filter(item => item.campaign === campaign)
    setProducts(temp);
    handleSelectedGivingProps();
  }
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // console.log("givingProducts=", givingProducts)
    // let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    // if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.currency !== selectedCurrency) {
    //   handleCurrency(selectedCurrency, basketStatesFromLocalStorage);
    // }
    setProducts(givingProducts);
    if (givingProducts[0]) {
      // handleSelectedGiving();
      // let temp = [];
      // let tempSelectedGiving = [];
      // givingProducts.map((item, index) => {
      //   basketStatesFromLocalStorage &&
      //     basketStatesFromLocalStorage.products.map(innerItem => {
      //       // console.log("useeffect=",innerItem.product, item)
      //       if (innerItem.product && innerItem.product.creator === item.creator) {
      //         item = Object.assign({}, item, {
      //           isAdded: true,
      //           quantity: innerItem.quantity,
      //         });
      //         tempSelectedGiving.push(item);
      //       }
      //       return innerItem;
      //     });
      //   temp[index] = item;
      //   return item;
      // });
      // let tempTotalGiving = 0;
      // tempSelectedGiving.map(item => {
      //   tempTotalGiving = tempTotalGiving + (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
      //   return item;
      // });
      // setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
      // // console.log("selected=", tempSelectedGiving, tempTotalGiving, givingProducts)
      // setProducts(temp);
      // setProducts(givingProducts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingProducts, selectedCurrency]);

  // const handleMinimize = () => {
  //   setIsMinimized(!isMinimized);
  // };
  // const handleBack = () => {
  //   // navigate(-1);
  //   if (window.history.state && window.history.state.idx > 0) {
  //     navigate(-1);
  //   } else {
  //     navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
  //   }
  // };
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="flex flex-col w-full justify-center mb-0 md:mb-6" ref={contentRef}>
        <h2 className={`text-center text-DarkerBlue text-lg md:text-3xl w-full font-bold mb-2`}>{title}</h2>
      </div>
      <div ref={contentRef} className="md:px-4 overflow-hidden- max-w-full relative">
        <div className="md:w-[95%] mx-auto 2xl:w-full flex-wrap justify-center items-center relative">
          {products.length > 1 && (
            <div className="hidden md:flex">
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -left-[18px] md:-left-[36px] z-10 rounded-full ${currentSlide === 0 ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]' : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                    }`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                >
                  <LiaAngleLeftSolid size={25} />
                </div>
              )}
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -right-[18px] md:-right-[36px] z-10 rounded-full ${currentSlide === instanceRef.current.track.details.slides.length
                    ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]'
                    : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                    }`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                >
                  <LiaAngleRightSolid size={25} />
                </div>
              )}
            </div>
          )}
          {showAllBoxes ? (
            <>
              <div ref={sliderRef} className="keen-slider !hidden sm:!flex">
                {products.map((item, index) => {
                  return (
                    <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
                      <div className="w-full max-w-[355px]">
                        <AppealGivingProductBox
                          product={item}
                          lang={lang}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          onAddQty={() => handleAddQty(item, index)}
                          onReduceQty={() => handleReduceQty(item, index)}
                          onAddToGiving={() => handleAddToGiving(item, index)}
                          basket={basketStatesFromLocalStorage}
                        // isAdded={item.isAdded || false}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="sm:hidden w-full grid grid-cols-1 gap-2 py-4">
                {products.map((item, index) => {
                  return (
                    <div key={`onmobile${index}`} className="col-span-1 w-full max-w-[355px] mx-auto">
                      <AppealGivingProductBox
                        product={item}
                        lang={lang}
                        currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        onAddQty={() => handleAddQty(item, index)}
                        onReduceQty={() => handleReduceQty(item, index)}
                        onAddToGiving={() => handleAddToGiving(item, index)}
                        basket={basketStatesFromLocalStorage}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div ref={sliderRef} className="keen-slider">
                {products.map((item, index) => {
                  return (
                    <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
                      <div className="w-full max-w-[355px]">
                        <AppealGivingProductBox
                          product={item}
                          lang={lang}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                          onAddQty={() => handleAddQty(item, index)}
                          onReduceQty={() => handleReduceQty(item, index)}
                          onAddToGiving={() => handleAddToGiving(item, index)}
                          basket={basketStatesFromLocalStorage}
                        // isAdded={item.isAdded || false}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {products.length > 1 && (
                <div className="flex md:hidden bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
                  {loaded && instanceRef.current && (
                    <div className="flex justify-center items-center relative w-full">
                      {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                        return (
                          <button
                            key={`q${idx}`}
                            onClick={() => {
                              instanceRef.current?.moveToIdx(idx);
                            }}
                            className={
                              'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                              (currentSlide === idx ? ' bg-LightBlue hover:opacity-60' : 'bg-[#C3C3C3]')
                            }
                          ></button>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {/* {selectedGivingProducts.products.length ? (
          <div className="fixed bottom-0 sm:hidden- [&>div]:w-full z-20- z-[2200000000] shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] w-full left-0 p-2 bg-LighterBlue">
            <div className="flex flex-col gap-1 items-center w-full justify-around mx-auto p-2 rounded-lg bg-white">
              <div className={`flex w-full flex-col border-b border-stone-300 ${isMinimized ? '' : 'mb-1'} pb-1 cursor-pointer`} onClick={handleMinimize}>
                <h2 className={`text-DarkerBlue text-lg md:text-2xl w-full font-bold w-100 flex justify-between`}>
                  Your Giving Amount{' '}
                  <ChevronDownIcon className={`w-4 h-4 cursor-pointer text-stone-600 transition-all duration-300 ${isMinimized ? ' transform rotate-180' : ''}`} />
                </h2>
                <div className="flex items-center">
                  <span className="text-DarkerBlue text-sm">
                    {selectedGivingProducts.products.length} items in Cart
                  </span>
                  <span className="text-LightBlue text-sm cursor-pointer ml-2" onClick={() => navigate('/checkout')}>
                    View Cart
                  </span>
                </div>
              </div>
              <div className={`transition-all duration-300 w-full ${isMinimized ? 'h-0' : 'h-auto'} overflow-hidden`}>
                <div className="flex justify-between items-center flex-col py-1 w-full border-b border-stone-200 max-h-[160px] overflow-y-auto">
                  {selectedGivingProducts.products.map((item, index) => {
                    if (showMoreItems) {
                      return (
                        <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                          <div className="text-stone-400 text-xs flex items-center">
                            <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                            <p className="text-stone-400 text-xs">{item.name}</p>
                          </div>
                          <p className="text-xs text-DarkerBlue">
                            {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                          </p>
                        </div>
                      );
                    } else {
                      return index < 2 && (
                        <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                          <div className="text-stone-400 text-xs flex items-center">
                            <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                            <p className="text-stone-400 text-xs">{item.name}</p>
                          </div>
                          <p className="text-xs text-DarkerBlue">
                            {ShowCurrencies(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
                {selectedGivingProducts.products.length > 2 && <div className="flex border-b border-stone-200 pb-2">
                  <span className="text-LightBlue text-xs flex items-center cursor-pointer mt-1" onClick={() => setShowMoreItems(!showMoreItems)}>
                    {showMoreItems
                      ? <>Show less items <ChevronDownIcon className={`w-3 h-3 text-stone-500 ml-2`} /></>
                      : <>View more items <ChevronUpIcon className={`w-3 h-3 text-stone-500 ml-2`} /></>
                    }
                  </span>
                </div>}
                <div className="flex justify-between items-center border-t border-stone-300 py-1 w-full">
                  <p className="text-stone-500 text-xs">Total Amount</p>
                  <p className="text-sm font-bold text-DarkerBlue">{ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}</p>
                </div>
              </div>
              <div className="flex items-center mt-1 w-full">
                {!isDetailsPage && <CustomButton
                  onClick={() => handleBack()}
                  title={'Back'}
                  type="button"
                  className={`w-auto !bg-[#00a3da] mr-2`} />}
                <CustomButton
                  title={
                    <>
                      {`Give `}
                      {ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}
                    </>
                  }
                  link={'/checkout'}
                  className={`${lang === 'ar' ? '' : 'font-["Montserrat"]'} font-bold w-full`}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )} */}
      </div>
    </>
  );
};
export default AppealLevelGiving;
