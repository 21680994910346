import { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import ShowCurrencies from '../../../utils/showCurrencies';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
import CustomButton from '../../../components/general/button';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { endDateOfAppealCampaign } from '../details';
import { store } from '../../../store/store';
import { pushEventToQueue } from '../../../store/gtm';

const AppealFundraiserProductBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const refImg = useRef(null);
  const refLazyImg = useRef(null);
  const { product, currency, goal, campaign } = props;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleViewItemDataLayer = (tempProduct, currency) => {
    let dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    store.dispatch(pushEventToQueue(dataLayerTemp));
  };
  const handlePercentage = (total_sale, goal) => {
    return `${((Number(total_sale) / Number(goal)) * 100).toFixed(2)}%`;
  };
  const handleDaysLeft = () => {
    var now = moment(new Date());
    // var before = moment(new Date(startDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    // var timeDifference = now.diff(before, 'days');
    var after = moment(new Date(endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    var timeDifference = after.diff(now, 'days');
    return timeDifference;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full rounded-xl shadow-lg bg-white overflow-hidden max-[767px]:max-w-[355px] mx-auto border border-solid border-[#C3C3C3]">
      <div className="overflow-hidden rounded-md w-full p-2 pb-0 ">
        <div
          ref={refImg}
          className="overflow-hidden cursor-pointer max-h-[250px]- max-h-[395px] rounded-md w-full flex items-center "
          onClick={() => navigate(`/appeals/${campaign === 'palestine' ? 'palestine-emergency' : 'palestine-refugee-resettlement'}`)}
        >
          {product ? (
            product.image_link ? (
              <LazyLoadImage
                ref={refLazyImg}
                alt={product.alt ? product.alt : product.name}
                effect="blur"
                width={`100%`}
                delayTime={500}
                className="rounded-md"
                afterLoad={() => {
                  handleViewItemDataLayer(product, currency);
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/general/logo-replace.png';
                }}
                src={
                  campaign === 'palestine'
                    ? 'https://cdn.matwproject.org/images/products/large-palestine+emergency.jpg'
                    : 'https://cdn.matwproject.org/images/products/large-palestine+refugee+settlement.jpg'
                }
              />
            ) : (
              <img className="w-full" src="/images/general/logo-replace.png" alt="" />
            )
          ) : (
            <Skeleton height={344} />
          )}
        </div>
      </div>
      <div className="p-2 md:p-3 md:pb-5">
        <h3
          className="text-DarkerBlue sm:text-md md:text-md lg:text-lg xl:text-xl font-medium h-[48px] lg:h-[56px] mb-2 flex items-center cursor-pointer"
          onClick={() => navigate(`/appeals/${campaign === 'palestine' ? 'palestine-emergency' : 'palestine-refugee-resettlement'}`)}
        >
          {product ? product.alternativeName || product.name : <Skeleton height={56} />}
        </h3>
        <div className="relative">
          {product ? (
            <>
              <div
                className={`my-2 relative overflow-hidden max-h-[60px] md:max-h-[80px] ${
                  (product.short_description || product.description).split(' ').length > 28 ? 'mb-4-' : 'mb-2-'
                }`}
              >
                <p ref={ref} className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]`}>
                  {product.short_description || product.description}
                </p>
              </div>
            </>
          ) : (
            <Skeleton count={4} />
          )}
        </div>
        <div className="basis-full flex-col md:basis-1/2 flex justify-center pt-1 md:pt-2">
          <div className="flex items-center justify-between mb-2">
            <div className="flex w-full text-sm">
              <strong className="text-DarkerBlue font-bold mr-0.5">{product.total_count}</strong>
              <span className="inline-block lg:w-auto mr-1">Supporters,</span>
              <strong className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft()}</strong>
              <span className="inline-block lg:w-auto">days left</span>
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            <div
              className={`bg-[#F60362]`}
              style={{ width: `${handlePercentage(ConvertUSD(currency, product.total_sale, false), ConvertUSD(currency, goal, false))}` }}
            ></div>
          </div>
        </div>
        <div className="basis-full flex justify-between items-center mt-2">
          <div className="flex flex-col w-full">
            <p className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
              {ShowCurrencies(currency, Math.round(ConvertUSD(currency, product.total_sale, false)), false)}
              <span className="text-xs ml-0.5">{currency}</span>
            </p>
            <div className="flex flex-wrap w-full text-sm items-center">
              <span className="text-[#78716C]">Raised of</span>
              <span className="text-DarkerBlue font-bold mx-1">{ShowCurrencies(currency, Math.round(ConvertUSD(currency, goal, false)))}</span>
              Goal
            </div>
          </div>
          {product ? (
            <CustomButton
              onClick={() => navigate(`/appeals/appeal/${campaign === 'palestine' ? 'palestine-emergency' : 'palestine-refugee-resettlement'}`)}
              type="button"
              title={'Donate'}
              className={`!rounded-full`}
            />
          ) : (
            <Skeleton height={43} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppealFundraiserProductBox;
