import { useEffect, useState } from 'react';
import services from '../../../../services';
import Carousel from '../../../../components/Carousel';

export default function Index({ data }) {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await services.getContent(data[0]?.image_carousel?.request?.url);
      setBanners(res.data?.data);
    }
    fetchData();
  }, data);

  return (
    <div className="md:container md:mx-auto mt-0 md:mt-2- md:mb-4">
      <div className="w-full md:py-2 min-h-[200px]- sm:min-h-[330px]- md:min-h-[393px] lg:min-h-[550px] xl:min-h-[674px] 2xl:min-h-[800px]">
        {!!banners.length && (
          <Carousel
            withArrow={false}
            autoSwitch={true}
            data={banners}
            renderItem={({ item, index, currentSlide }) => {
              const isActive = currentSlide === index;
              return (
                <div className="md:p-2" key={`carousel${index}`}>
                  <div
                    className={`w-full h-auto transition-all transform md:rounded-xl overflow-hidden lg:min-h-[490px] xl:min-h-[614px] 2xl:min-h-[740px] ${
                      isActive ? 'scale-100' : 'opacity-75 scale-90'
                    }`}
                  >
                    <a href={item.url}>
                      <img
                        className={`w-full object-center cursor-pointer object-cover md:rounded-xl `}
                        alt={item.altTag}
                        title={item.titleTag}
                        src={`${window.innerWidth > 500 ? item.webImage : item.mobileImage}`}
                      />
                    </a>
                  </div>
                </div>
              );
            }}
            keyExtractor={item => (window.innerWidth > 500 ? item.webImage : item.mobileImage)}
          />
        )}
      </div>
    </div>
  );
}
