import ReactSelect from 'react-select';
import usaImg from '../../images/flags/usa.svg';
import ausImg from '../../images/flags/aus.svg';
import canImg from '../../images/flags/can.svg';
import eurImg from '../../images/flags/eu.svg';
// import uaeImg from "../../images/flags/uae.svg";
// import uaeImg from "../../images/flags/uae.svg";
// import indImg from "../../images/flags/ind.svg";
import gbrImg from '../../images/flags/gbr.svg';
import mysImg from '../../images/flags/mys.svg';
import sgpImg from '../../images/flags/sgp.svg';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency, setSelectedCurrencyInStore } from '../../store/user';
// import HandleLocalStorage, { BASKET_CACHE_KEY } from "../../utils/handleLocalStorage";

const styles = {
  menuList: base => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& *': {
      cursor: 'pointer',
    },
  }),
};

const SelectCurrency = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const dispatch = useDispatch();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  let { onChangeCurrency, classNamePrefix, className, currencies, onMenuOpen, onMenuClose } = props;
  const currencyList = useMemo(() => {
    return (
      currencies ?? [
        {
          value: 'AUD',
          label: (
            <span className="flex items-center">
              {' '}
              <img src={ausImg} className="w-5 mr-1" /> AUD
            </span>
          ),
        },
        {
          value: 'USD',
          label: (
            <span className="flex items-center">
              {' '}
              <img src={usaImg} className="w-5 mr-1" /> USD
            </span>
          ),
        },
        {
          value: 'GBP',
          label: (
            <span className="flex items-center">
              {' '}
              <img src={gbrImg} className="w-5 mr-1" /> GBP
            </span>
          ),
        },
        {
          value: 'CAD',
          label: (
            <span className="flex items-center">
              {' '}
              <img src={canImg} className="w-5 mr-1" /> CAD
            </span>
          ),
        },
        {
          value: 'EUR',
          label: (
            <span className="flex items-center">
              {' '}
              <img src={eurImg} className="w-5 mr-1" /> EUR
            </span>
          ),
        },
        {
          value: 'SGD',
          label: (
            <span className="flex items-center">
              {' '}
              <img src={sgpImg} className="w-5 mr-1" /> SGD
            </span>
          ),
        },
        {
          value: 'MYR',
          label: (
            <span className="flex items-center">
              <img src={mysImg} className="w-5 mr-1" /> MYR
            </span>
          ),
        },
      ]
    );
  }, [currencies]);

  const [currentCurrency, setCurrentCurrency] = useState(currencyList[0]);

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleSelectCurrency = currency => {
    setCurrentCurrency(currency);
    onChangeCurrency(currency);
    dispatch(setSelectedCurrencyInStore(currency.value));
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore && currencyList?.length > 0) {
      const currency = currencyList.find(item => item.value === selectedCurrencyStore);
      if (currency) {
        setCurrentCurrency(currency);
      } else {
        const defaultUSDCurrency = currencyList.find(item => item.value === 'USD') ?? currencyList[0];
        setCurrentCurrency(defaultUSDCurrency);
        dispatch(setSelectedCurrencyInStore(defaultUSDCurrency.value));
      }
    }
  }, [selectedCurrencyStore, currencyList]);
  /* -------------------------------------------------------------------------- */
  return (
    <ReactSelect
      options={currencyList}
      className={`max-h-[48px] [&>*]:!cursor-pointer w-full [&>div]:!rounded-md ${className}`}
      // defaultValue={currencies[0]}
      classNamePrefix={classNamePrefix ? classNamePrefix : ''}
      value={currentCurrency}
      isSearchable={false}
      styles={styles}
      onChange={e => handleSelectCurrency(e)}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export default SelectCurrency;
