import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShowCurrencies from '../../../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CustomButton from '../../../../components/general/button';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../../../store/products';
import { store } from '../../../../store/store';
import { pushEventToQueue } from '../../../../store/gtm';
import ConvertUSD from '../../../../utils/convertUSD';
import moment from 'moment';

const handleDaysLeft = date => {
  var now = moment(new Date());
  var after = moment(new Date(date), 'DD-MM-YYYY HH:mm:ss');
  var timeDifference = after.diff(now, 'days');
  return timeDifference;
};

const handlePercentage = (total_sale, goal) => {
  return `${((Number(total_sale) / Number(goal)) * 100).toFixed(2)}%`;
};

function handleConvertToDate(dateString) {
  const [day, month, year] = dateString.split('-');
  return new Date(year, month - 1, day);
}

const ProductBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const [addProduct] = useHandleAddProduct();
  const {
    product,
    currency,
    isStaticProduct,
    isPalestineRefugee = false,
    arabic = false,
    staticLink,
    staticLinkText,
    buttonLable,
    stateVariableObj,
    scrollTop,
    linkBtnClasses,
    titleClasses,
    descriptionStyle,
    lang = 'en',
    hasTarget = false,
    removeDataLayer,
  } = props;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isAnyAmount, setIsAnyAmount] = useState(false);
  const pixelID = localStorage.getItem('TikTokID');
  const allProducts = useSelector(selectProducts);
  const description = product?.shortDescription || product?.description || '';
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = currency => {
    if (product.quantity) {
      return ShowCurrencies(currency, product[currency.toLowerCase()] * product.quantity, false);
    } else {
      return ShowCurrencies(currency, product.prices?.find(p => p.name === currency)?.data?.amount ?? product[currency.toLowerCase()], false);
    }
  };
  const handleCustomProductAmount = product => {
    // console.log("product=",product)
    if (Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) || product.id === 52 || product.id === 69) {
      setIsAnyAmount(true);
    } else {
      // if (Number(product[currency.toLowerCase()]) === 1) {
      let hasQuantity = product.quantity ? (product.quantity > 1 ? true : false) : false;
      // if (Number(product[currency.toLowerCase()]) < 6 && !hasQuantity) {
      if (Number(product.aud) < 2 && !hasQuantity) {
        setIsAnyAmount(true);
      } else {
        setIsAnyAmount(false);
      }
    }
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handleViewItemDataLayer = (tempProduct, currency) => {
    // console.log("datalayer view item", tempProduct)
    let dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    store.dispatch(pushEventToQueue(dataLayerTemp));

    let obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category ? tempProduct.category : 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description,
    };
    window.ttq &&
      window.ttq.instance(pixelID).track('ViewContent', {
        ...obj,
      });
  };

  const handleAddToBasket = () => {
    rootLoop: for (let item of allProducts) {
      for (let inner_item of item.sub_categories) {
        for (let inner_item_product of inner_item.products) {
          if (inner_item_product.creator === product.creator) {
            addProduct(inner_item_product, currency);
            break rootLoop;
          }
        }
      }
    }
  };

  useEffect(() => {
    product && handleCustomProductAmount(product);
    product && ref.current.clientHeight > 79 && setIsOverflow(true);
    if (product.customDonation) {
      setIsAnyAmount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  /* -------------------------------------------------------------------------- */

  console.log('product', product);
  return (
    <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white">
      <div
        // className="overflow-hidden img-hover-zoom rounded-md max-h-[336px]- w-full sm:w-[197px]- sm:h-[169px] md:w-[210px] md:h-[210px] lg:w-[301px] lg:h-[301px] xl:w-[280px] xl:h-[280px] 2xl:w-[344px] 2xl:h-[344px] tooltip [&>span]:!block"
        className="overflow-hidden img-hover-zoom rounded-md w-full sm:min-h-[169px] md:min-h-[210px] lg:min-h-[301px] xl:min-h-[280px] 2xl:min-h-[344px] tooltip [&>span]:!block"
        // className="overflow-hidden img-hover-zoom rounded-md w-full tooltip [&>span]:!block"
        onClick={() => {
          isPalestineRefugee
            ? navigate(`/appeals/palestine/${product.name.replace(/\s+/g, '-').toLowerCase()}`)
            : isStaticProduct
            ? navigate(`${staticLink ? staticLink : '/zakat-calculator'}`)
            : navigate(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`);
        }}
      >
        {product ? (
          product.webImage?.[0] || product.image_link ? (
            <LazyLoadImage
              alt={product.altTag}
              effect="blur"
              width={`100%`}
              delayTime={500}
              afterLoad={() => {
                !removeDataLayer && handleViewItemDataLayer(product, currency);
              }}
              className="rounded cursor-pointer block"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              src={window.innerWidth > 500 ? product.webImage?.[0] || product.image_link : product.mobileImage?.[0] || product.image_link}
            />
          ) : (
            <img className="w-full" src="/images/general/logo-replace.png" alt="" />
          )
        ) : (
          <Skeleton height={344} />
        )}
        {/* <span className="tooltiptext bg-[#00a3da] rounded-lg cursor-pointer mx-auto left-0 right-0 z-[1] absolute py-1 text-center text-white opacity-0 transition-all duration-300 w-32 top-[calc(50%_-_17px)]">Details</span> */}
      </div>
      <p
        className={`text-[#00a3da] cursor-pointer flex justify-center items-center text-md sm:text-md md:text-md lg:text-lg xl:text-xl text-center h-[42px] sm:h-[40px] lg:h-[56px]  my-2 leading-4 md:leading-none uppercase ${
          arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-medium'
        } ${titleClasses ? titleClasses : ''}`}
        onClick={() => {
          isPalestineRefugee
            ? navigate(`/appeals/palestine/${product.name.replace(/\s+/g, '-').toLowerCase()}`)
            : isStaticProduct
            ? navigate(`${staticLink ? staticLink : '/zakat-calculator'}`)
            : navigate(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`);
        }}
      >
        {/* {product ? product.name : <Skeleton height={56} />} */}
        {product ? product.alternativeName || product.name : <Skeleton height={56} />}
      </p>
      <div className="relative text-center">
        {product ? (
          <>
            <div
              className={`transition-all duration-300 text-center my-2 relative overflow-hidden ${
                showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[78px]'
              } ${description.split(' ').length > 28 ? 'mb-0' : 'mb-0'}`}
            >
              <p
                ref={ref}
                className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] ${
                  descriptionStyle ? descriptionStyle : ''
                } ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
              >
                {description}
              </p>
            </div>
            <div className="flex h-8 pb-1">
              {/* {isOverflow && <span className='w-[96px] h-7 sm:h-8 pb-0.5 bg-[#00a3da] rounded-md text-sm sm:text-base text-white flex justify-center items-center mx-auto cursor-pointer text-center' onClick={() => handleHeight()}>{showMore ? arabic ? 'عرض أقل' : 'Show less' : arabic ? 'أظهر المزيد' : 'Show more'}</span>} */}
              {isOverflow && (
                <ChevronDownIcon
                  onClick={() => handleHeight()}
                  className={`w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${showMore ? ' transform rotate-180' : ''}`}
                />
              )}
            </div>
          </>
        ) : (
          <Skeleton count={4} />
        )}
      </div>
      {hasTarget ? (
        <>
          <div className="basis-full flex-col md:basis-1/2 flex justify-center pt-1 md:pt-2">
            <div className="flex items-center justify-between mb-2">
              <div className="flex w-full text-sm">
                <strong className="text-DarkerBlue font-bold mr-0.5">{product.donationCount}</strong>
                <span className="inline-block lg:w-auto mr-1">Supporters,</span>
                <strong className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft(handleConvertToDate(product.targetEndDate ?? ''))}</strong>
                <span className="inline-block lg:w-auto">days left</span>
              </div>
            </div>
            <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(
                    ConvertUSD(currency, product.goal_progress?.total, false),
                    ConvertUSD(currency, product.goal_progress?.target_amount, false),
                  )}`,
                }}
              ></div>
            </div>
          </div>
          <div className="basis-full flex justify-between items-center mt-2">
            <div className="flex flex-col w-full">
              <p className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                {ShowCurrencies(currency, Math.round(ConvertUSD(currency, product.goal_progress?.total, false)), false)}
                <span className="text-xs ml-0.5">{currency}</span>
              </p>
              <div className="flex flex-wrap w-full text-xs items-center">
                <span className="text-[#78716C] text-xs">Raised of</span>
                <span className="text-DarkerBlue font-bold mx-1 text-sm">
                  {ShowCurrencies(currency, Math.round(ConvertUSD(currency, product.goal_progress?.target_amount, false)))}
                </span>
                Goal
              </div>
            </div>
            {product ? (
              <CustomButton onClick={handleAddToBasket} type="button" title={'Donate'} className={`!rounded-full`} />
            ) : (
              <Skeleton height={43} />
            )}
          </div>
        </>
      ) : (
        <div className="grid grid-cols-6 gap-1 2xl:gap-2">
          {product ? (
            <p
              className={`text-[#f60362] col-span-6 text-md md:text-xl text-center font-bold ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
            >
              {isStaticProduct ? (
                ' '
              ) : isAnyAmount ? (
                arabic || lang === 'ar' ? (
                  'أي مبلغ'
                ) : lang === 'fr' ? (
                  `N'importe quel montant`
                ) : (
                  'Any Amount'
                )
              ) : (
                <>
                  {handleCurrency(currency)} <small className={`uppercase`}>{currency}</small>
                </>
              )}
            </p>
          ) : (
            <div className="col-span-6">
              <Skeleton />
            </div>
          )}

          <div className="flex justify-center col-span-6">
            {product ? (
              isStaticProduct ? (
                scrollTop >= 0 ? (
                  <CustomButton
                    onClick={() => {
                      window.scroll({ top: scrollTop, left: 0, behavior: 'smooth' });
                    }}
                    title={staticLinkText ? staticLinkText : 'Zakat Calculator'}
                    className={`${linkBtnClasses ? linkBtnClasses : ''}`}
                  />
                ) : (
                  <CustomButton
                    state={stateVariableObj}
                    link={`${staticLink ? staticLink : '/zakat-calculator'}`}
                    title={staticLinkText ? staticLinkText : 'Zakat Calculator'}
                    className={`${linkBtnClasses ? linkBtnClasses : ''}`}
                  />
                )
              ) : (
                <CustomButton
                  onClick={handleAddToBasket}
                  title={
                    buttonLable ? buttonLable : arabic || lang === 'ar' ? 'تبرع الان' : lang === 'fr' ? 'Faites un don maintenant' : 'Donate Now'
                  }
                  className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
                />
              )
            ) : (
              <Skeleton height={43} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductBox;
