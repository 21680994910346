import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userState: {}, // For create new user on step 2 - store fields
    urlInfo: {}, // For get id param from url and decode it
    createdUser: {}, // For create new user on step 2 - Response of created user
    createdUserPlaqueName: {}, // For create new user on step 2 - Plaque Name
    // sessionId: {}, // For store sessionId
    productDetail: {}, // For store product info on step 1
    productDetailPaypal: {}, // For store product info for paypal method on step 1
    receiveProduct: {},
    userProductsInStore: {},
    paypalPlanId: '',
    userIP: '',
    // selectedProduct: {},
    createPaypalSubscription: {},
    createPaypalOrder: {},
    // createPaypalOrder: {},
    selectedCurrency: '',
    countryCode: '',
    country: '',
    payment_flow: '',
  },
  reducers: {
    setUserState(state, action) {
      state.userState = action.payload;
    },
    // setSessionId(state,action) {
    //     state.sessionId = action.payload;
    // },
    setCreatedUser(state, action) {
      state.createdUser = action.payload;
    },
    setCreatedUserPlaqueName(state, action) {
      state.createdUserPlaqueName = action.payload;
    },
    setReceiveProduct(state, action) {
      state.receiveProduct = action.payload;
    },
    setUserProductsInStore(state, action) {
      state.userProductsInStore = action.payload;
      console.log('user products', action.payload);
    },
    setPaypalPlanId(state, action) {
      state.paypalPlanId = action.payload;
    },
    setUserIP(state, action) {
      state.userIP = action.payload;
    },
    // setSelectedProduct(state,action) {
    //     state.selectedProduct = action.payload;
    // },
    setCreatePaypalSubscription(state, action) {
      state.createPaypalSubscription = action.payload;
    },
    setCreatePaypalOrder(state, action) {
      state.createPaypalOrder = action.payload;
    },
    setSelectedCurrencyInStore(state, action) {
      state.selectedCurrency = action.payload;
    },
    setCountryCode(state, action) {
      state.countryCode = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setPaymentFlow(state, action) {
      state.payment_flow = action.payload;
    },
  },
});

export const {
  setUserState,
  setCreatedUser,
  setReceiveProduct,
  setUserProductsInStore,
  setPaypalPlanId,
  setUserIP,
  setCreatePaypalSubscription,
  setCreatePaypalOrder,
  setCreatedUserPlaqueName,
  setSelectedCurrencyInStore,
  setCountryCode,
  setCountry,
  setPaymentFlow,
} = userSlice.actions;
export const selectUserState = state => state.user.userState;
export const selectCreatedUser = state => state.user.createdUser;
// export const selectSessionId = (state) => state.user.sessionId;
export const selectReceiveProduct = state => state.user.receiveProduct;
export const selectUserProductsInStore = state => state.user.userProductsInStore;
export const selectPaypalPlanId = state => state.user.paypalPlanId;
export const selectUserIP = state => state.user.userIP;
export const selectCreatePaypalSubscription = state => state.user.createPaypalSubscription;
export const selectCreatePaypalOrder = state => state.user.createPaypalOrder;
export const selectCreatedUserPlaqueName = state => state.user.createdUserPlaqueName;
export const selectSelectedCurrency = state => state.user.selectedCurrency;
export const selectSelectedCountryCode = state => state.user.countryCode;
export const selectSelectedCountry = state => state.user.country;
export const selectSelectedPaymentFlow = state => state.user.payment_flow;

export default userSlice.reducer;
