import React from 'react';
import { decodeHtml } from '../../../../utils/decodeHTML';
import { fontTypeMatcher } from '../../../../utils/helperFunctions';
import useMediaQuery from '../../../../hooks/useMediaQuery';

const DefaultTextSection = props => {
  const { data } = props;
  const title = data[0]?.title?.value ?? '';
  const secondTitle = data[0]?.second_title?.value ?? '';
  const content = data[0]?.content?.value ?? '';
  const mobileContent = data[0]?.content_responsive?.value ?? '';
  const titleFont = data[0]?.title_text_type?.value;
  const secondTitleFont = data[0]?.second_title_text_type?.value;
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className="bg-white mt-5 px-2.5">
      <div className="rounded-xl md:container md:mx-auto  p-6 lg:p-12 text-center">
        <p className={`text-[30px] sm:text-[48px] text-[#00a3da] mb-5 md:mb-6 lg:mb-8 ${fontTypeMatcher(titleFont)}`}>{title}</p>
        {secondTitle && <h2 className={`text-[#777] text-lg lg:text-xl mb-4 ${fontTypeMatcher(secondTitleFont)}`}>{secondTitle}</h2>}
        {content && (
          <div
            className="text-[#999] text-base [&_div]:!max-w-full [&_h2]:!leading-none"
            dangerouslySetInnerHTML={{ __html: decodeHtml(isMobile ? mobileContent : content) }}
          />
        )}
      </div>
    </div>
  );
};

export default DefaultTextSection;
