import React, { useState, useRef, useEffect } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];

const AddressField = ({ label, defaultValue, setFieldValue, handleBlur, errors, touched, name, handleChange, placeholder, onData, value }) => {
  const [inputValue, setInputValue] = useState(defaultValue ?? '');
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  const onLoad = autocomplete => {
    inputRef.current = autocomplete;
  };

  const extractLocation = htmlString => {
    console.log(htmlString);
    const regionRegex = /<span class="region">(.*?)<\/span>/;
    const localityRegex = /<span class="locality">(.*?)<\/span>/;
    const streetAddressRegex = /<span class="street-address">(.*?)<\/span>/;
    const postalCodeRegex = /<span class="postal-code">(.*?)<\/span>/;

    const regionMatch = regionRegex.exec(htmlString);
    const localityMatch = localityRegex.exec(htmlString);
    const streetAddressMatch = streetAddressRegex.exec(htmlString);
    const postalCodeMatch = postalCodeRegex.exec(htmlString);

    const region = regionMatch ? regionMatch[1] : null;
    const locality = localityMatch ? localityMatch[1] : null;
    const streetAddress = streetAddressMatch ? streetAddressMatch[1] : null;
    const postalCode = postalCodeMatch ? postalCodeMatch[1] : null;

    return { region, locality, streetAddress, postalCode };
  };

  const onPlaceChanged = () => {
    if (inputRef.current !== null) {
      const place = inputRef.current.getPlace();
      const { region, locality, streetAddress, postalCode } = extractLocation(place.adr_address);

      setFieldValue('address', streetAddress);
      setFieldValue('city', locality);
      setInputValue(streetAddress);
      onData({ locality, postalCode });
    }
  };

  const onChange = e => {
    setInputValue(e.target.value);
    handleChange(e);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCXVJ062M2GPfxr4DvmF55qSbSp4qh0S6U" libraries={libraries}>
      <div className="relative">
        <label className="text-sm md:text-md text-[#777]">
          {label}
          <small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
        </label>
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
            onBlur={handleBlur}
            value={inputValue}
            onChange={onChange}
          />
        </Autocomplete>
        {errors && touched && <small className="text-[#f60362] text-sm">{errors}</small>}
      </div>
    </LoadScript>
  );
};

export default AddressField;
