import { combineReducers } from 'redux';

import user from './user';
import products from './products';
import auth from './auth';
import visibility from './visibility';
import ipCurrency from './ipCurrency';
import gtm from './gtm';

const allReducers = combineReducers({
  gtm,
  auth,
  user,
  products,
  ipCurrency,
  visibility,
});
export default allReducers;
// export default allReducers;
