import { createSlice } from '@reduxjs/toolkit';
import {userLogin, registerUser} from './actions/authActions';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        user: {},
        userToken: localStorage.getItem('token') ? localStorage.getItem('token') : null,
        error: null,
        success: null,
        userRegister: null,
        userForgotPassword: null,
        isForgetPassword: false,
        isLogin: false
    },
    reducers: {
        setAuthUser(state, action){
            state.user = action.payload.user;
            state.userToken = action.payload.token;
            localStorage.setItem('token', action.payload.token);
        },
        setUserLogin(state, action){
            state.user = action.payload;
            state.isLogin = true;
        },
        removeUser(state){
            state.user = {}
        },
        setUserRegister(state, action){
            state.userRegister = action.payload
        },
        setUserForgotPassword(state, action){
            state.isForgetPassword = true;
            state.userForgotPassword = action.payload;
        }
    },
    extraReducers: {
        [userLogin.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [userLogin.fulfilled]: (state, { payload }) => {
          state.loading = false;
          state.user = payload.data?.user;
          state.userToken = payload.data?.token;
          state.success = true;
        },
        [userLogin.rejected]: (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.success = false;
        },
        [registerUser.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [registerUser.fulfilled]: (state, { payload }) => {
          state.loading = false;
          state.userRegister = payload.user;
          state.success = true;
        },
        [registerUser.rejected]: (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.success = false;
        },
    }
})

export const {setAuthUser, removeUser, setUserRegister, setUserForgotPassword, setUserLogin } = authSlice.actions;

export default authSlice.reducer;