import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getProducts } from '../../../../services/content';
import ProductBox from '../../../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../store/user';
import MultiProductsBox from '../multi-products-box';
import { fontTypeMatcher } from '../../../../utils/helperFunctions';

const ProductsGroupCard = props => {
  const { data } = props;
  const [products, setProducts] = useState([]);
  const currency = useSelector(selectSelectedCurrency);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getProducts(data[0].product?.request?.url);
        setProducts(res.data?.data);
      } catch (err) {
        toast.error(err);
      }
    }
    fetchData();
  }, []);

  const withGroup = data?.[0]?.product?.custom_attributes?.withGroup;
  const hasTarget = !!data[0]?.with_target?.value;

  return (
    <section className="md:container md:mx-auto mt-5 sm:mt-20 px-4 md:px-0 pb-5">
      <div className={`text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-3 ${fontTypeMatcher(data[0].title_text_type?.value)}`}>
        {data[0].title?.value}
      </div>
      <div className="flex justify-center text-[18px]" dangerouslySetInnerHTML={{ __html: data[0].content?.value }}></div>
      <div className="flex flex-wrap justify-center gap-2 md:gap-4 xl:gap-6 md:gap-y-10 mt-8 ">
        {products?.map((item, index) => (
          <div key={index} className="basis-[48%] lg:basis-1/3 xl:basis-1/4 max-w-[300px]">
            {withGroup && !!item.groups?.length ? (
              <MultiProductsBox
                multiProducts={item.groups}
                staticLink={'/'}
                title={item.title}
                description={item.description}
                image={item.img}
                alt={item?.alt}
                currency={currency ? currency : 'AUD'}
                showMultiProductsOnRow
              />
            ) : (
              <ProductBox
                stateVariableObj={{ productName: item.name }}
                product={item}
                lang={'en'}
                hasTarget={hasTarget}
                linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                isStaticProduct={item.isStaticProduct}
                staticLink={item.staticLink}
                staticLinkText={item.staticLinkText}
                currency={currency ? currency : 'AUD'}
                titleClasses={`uppercase`}
              />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductsGroupCard;
