import React, { useMemo } from 'react';

const VideoBanner = props => {
  const { data } = props;

  const embedUrl = useMemo(() => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?/;
    const matches = data[0]?.url?.value?.match(regex);
    if (!matches) return '';
    return `https://www.youtube.com/embed/${matches[1]}/?autoplay=${data[0]?.autoplay?.value ? '1' : '0'}&loop=${data[0]?.loop?.value ? '1' : '0'}&controls=${data[0]?.controls?.value ? '1' : '0'}`;
  }, [data[0]?.url?.value]);

  return (
    <div className={` ${data[0]?.full_wide?.value ? '' : 'md:container md:mx-auto p-2 lg:p-12'}  mt-5 rounded-xl bg-white text-center shadow-lg`}>
      <div className="h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
        <iframe
          className="video"
          allow="autoplay"
          width={'100%'}
          height={'100%'}
          title={'youtube'}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={embedUrl}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoBanner;
